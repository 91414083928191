import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Pagination,
  Table,
  Dropdown,
  Form,
  ButtonGroup,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import ApplicationSubmissionService from "../services/service/ApplicationSubmissionService";
import ErrorHandler from "../services/constant/ErrorHandler";
import UserDetails from "../services/storage/UserDetails";
import DashboardLink from "./DashboardLink";
import MUIDataTable from "mui-datatables";
import ApplicationService from "../services/service/ApplictionService";
import ApplicationUpdateService from "../services/service/ApplicationUpdateService";
import ReportOneg from "./Report/thirdchangeReport";
import BuildingPermissionOrder from "../screens/form/BuildingPermissionOrder";
import DemandNoticeSample from "../screens/form/DemandNoticeSample";
import { PDFExport } from "@progress/kendo-react-pdf";
import HighRise from "../services/constant/HighRise";
import Header from "./Header";
import moment from "moment/moment";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

const ActivityLogList = () => {
  const { saveHighRiseStatus } = HighRise();
  const { userDetails } = UserDetails();
  const location = useLocation();
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (location?.state?.id) {
      ApplicationService.user_application_activities(location?.state?.id)
        .then((result) => {
          setList(result.data.user_application_activities);
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      history.goBack();
    }
  }, []);
  let active = 2;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const viewPreCheckReport = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalData(response.precheck_errors);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewDrawingPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalData(response.scrutiny_report_pdf);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewScrutinyReportPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        viewReport(response);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewReport = async (values) => {
    let temp = {};
    // let details = values.scrutiny_report_datas;
    // let anpdf = values.scrutiny_report_pdf;
    // temp.owner = { firstName: details.applicant_name, lastName: details.applicant_name, email: details.email, mobile: details.mobile };
    // temp.mobile = details.mobile;
    // temp.architect = { name: details.applicant_name, email: details.email, mobile: details.mobile };
    // temp.appliedOn = details.receipt_date;
    // temp.applicationId = details.application_number;
    // temp.report = drawing;
    let drawing = JSON.parse(values.rule_engine_json_response);
    let details = values.scrutiny_report_datas;
    let anpdf = values.scrutiny_report_pdf;
    temp.owner = {
      firstName: details.applicant_name,
      lastName: details.applicant_name,
      email: details.email,
      address: details.address,
    };
    temp.mobile = details.mobile;
    temp.surveyNo = details.sector_number;
    temp.doorNo = details.plot_number;
    temp.locality = details.mandal_name;
    temp.village = details.village_name;
    temp.siteaddress = details.site_address;
    temp.useOfTheBuildings = details.land_use_category;
    temp.subuseOfTheBuildings = details.land_subuse_category;
    temp.existingRoadType = details.road_type;
    temp.proposedRoadType = details.land_subuse_category;
    temp.architect = {
      name: details.applicant_name,
      email: details.email,
      mobile: details.mobile,
    };
    temp.appliedOn = details.receipt_date;
    temp.applicationId = details.application_number;
    temp.report = drawing;
    // var temp = {};
    // temp.owner = { firstName: 'test', lastName: 'test2', email: 'test@gmail.com', mobile: '9566673188' };
    // temp.mobile = "9266673188";
    // temp.architect = { name: 'test', email: 'test@gmail.com', mobile: '9566673188' };
    // temp.report = JSON.parse(values.rule_engine_json_response);
    // temp.report = test1;
    let newreport = new ReportOneg(temp);
    let results = await newreport.show();
    setModalData(results);
    setModal(true);
  };
  const columns = [
    {
      name: "sl",
      label: "S.No",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{tableMeta.rowIndex + 1}</div>;
        },
      },
    },
    {
      name: "activityable_type",
      label: "Type",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let value_ = value.toLowerCase();
          let final_ = value_.includes("user");
          return <div>{final_ ? "User" : "Staff"}</div>;
        },
      },
    },
    {
      name: "activityable",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {value.name
                ? value.name
                : `${value.first_name} ${value.last_name}`}
            </div>
          );
        },
      },
    },
    {
      name: "activity",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "created_at",
      label: "Date and Time",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>{value ? moment(value).format("YYYY-MM-DD hh:mm A") : ""}</div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    responsive: "scroll",
    download: false,
    sort: false,
    selectableRows: false,
    print: false,
    viewColumns: false,
    searchOpen: true,
    search: false,
    customSearchRender: () => null,
  };

  return (
    <Col className="rt-dashboard">
      <Header />
      <Row className="px-2 bg-green p-2 border-bottom">
        <Col className="col-12 col-md-3 mb-2 mb-md-0">
          <p className="f14 mb-0">
            <i>File No: {location?.state?.file_no}</i>
          </p>
        </Col>
        <Col className="col-12 col-md-3 text-md-center mb-2 mb-md-0">
          <h4 className="f14 mb-0">
            <i>Proposal Status: Draft Mode</i>
          </h4>
        </Col>
        <Col className="col-12 col-md-6 text-md-end mb-2 mb-md-0">
          <p className="f14 mb-0">
            <i>
              {userDetails?.type == 1
                ? "Citizen"
                : userDetails?.type == 2
                ? "Architect"
                : "Structural Engineer"}{" "}
              : {userDetails?.title}. {userDetails?.first_name}{" "}
              {userDetails?.middle_name} {userDetails?.last_name}
            </i>
          </p>
        </Col>
      </Row>

      <Col className="d-flex justify-content-between w-100 px-2 bg-green border-bottom">
        <Link
          to={{
            pathname: "/application-view",
            state: location?.state,
          }}
          className="d-flex black align-items-center p-2"
        >
          <FontAwesomeIcon icon={faArrowCircleLeft} />
          <p className="mb-0 ms-2">Back</p>
        </Link>
        <Button className="proposal-risk-back">Proposal Risk Category</Button>
      </Col>
      <Col className="col-12 mt-4 px-3">
        <Row className="mt-3 mb-3">
          <h5>Activity Log</h5>
        </Row>
        <MUIDataTable
          title={"Activity Log"}
          data={list}
          columns={columns}
          options={options}
        />

        {modal === true ? (
          <>
            <Modal
              show={modal}
              onHide={() => {
                setModal(false);
              }}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header closeButton>
                <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                  Report
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                </Row>
              </Modal.Body>
            </Modal>
          </>
        ) : null}
      </Col>
    </Col>
  );
};

export default ActivityLogList;
