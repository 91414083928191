import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Pagination,
  Table,
  Dropdown,
  Form,
  ButtonGroup,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationUpdateService from "../../services/service/ApplicationUpdateService";
import ApplicationService from "../../services/service/ApplictionService";
import ReportOneg from "../Report/thirdchangeReport";
import { PDFExport } from "@progress/kendo-react-pdf";
import { logoImage } from "../../assets/images/Index";
import PaymentService from "../../services/service/PaymentService";
import moment from "moment/moment";
import UserDetails from "../../services/storage/UserDetails";
const DemandNoteIssued = (props) => {
  const [list, setList] = useState([]);
  const { userDetails } = UserDetails();
  const [userApplicationId, setUserApplicationId] = useState("");
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const pdfExportComponent = React.useRef(null);
  const [tList, setTList] = useState([
    { date: "11-08-2020", amount: 10000 },
    { date: "11-09-2020", amount: 20000 },
    { date: "11-10-2020", amount: 30000 },
    { date: "11-11-2020", amount: 40000 },
    { date: "11-12-2020", amount: 50000 },
  ]);
  useEffect(() => {
    ApplicationService.getApplicationList({ type: "demand_note_issued" })
      .then((result) => {
        setList(result.data.user_application_demand_note_issued);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);
  const [modalType, setModalType] = useState("");
  const viewPreCheckReport = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("error");
        setModalData(response.precheck_errors);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewDrawingPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("report");
        setModalData(response.scrutiny_report_pdf);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewScrutinyReportPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        // viewReport(response);
        setModalType("report");
        setModalData(response?.scrutiny_report);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const changeStatus = (value) => {
    ApplicationUpdateService.changePaymetStatus(value)
      .then((res) => {
        alert(res?.data?.message);
        window.location.reload();
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  // NEW CHECK

  const [addres, setAddres] = useState("");
  let address = "Plot No:";
  useEffect(() => {
    address = address += userDetails?.user_detail?.address
      ? userDetails?.user_detail?.address
      : null;

    // address += userDetails?.user_detail?.city
    //   ? ", " + userDetails?.user_detail?.city
    //   : null;
    // address += userDetails?.user_detail?.state
    //   ? ", " + userDetails?.user_detail?.state
    //   : null;
    // address += userDetails?.user_detail?.pin_code
    //   ? ", " + userDetails?.user_detail?.pin_code + "."
    //   : null;
    setAddres(address);
  }, []);

  // console.log("addres", addres);

  const viewRecepit = (value) => {
    PaymentService.payment_receipt({
      user_application_id: value.id,
      type: 1,
    })
      .then((result) => {
        let response = result.data;
        setModalType("receipt");
        setModalData(response);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  // NEW CHECK

  const viewReport = async (values) => {
    let temp = {};
    let drawing = JSON.parse(values.rule_engine_json_response);
    let details = values.scrutiny_report_datas;
    let anpdf = values.scrutiny_report_pdf;
    temp.owner = {
      firstName: details.applicant_name,
      lastName: details.applicant_name,
      email: details.email,
      address: details.address,
    };
    temp.mobile = details.mobile;
    temp.surveyNo = details.sector_number;
    temp.doorNo = details.plot_number;
    temp.locality = details.mandal_name;
    temp.village = details.village_name;
    temp.siteaddress = details.site_address;
    temp.useOfTheBuildings = details.land_use_category;
    temp.subuseOfTheBuildings = details.land_subuse_category;
    temp.existingRoadType = details.road_type;
    temp.proposedRoadType = details.land_subuse_category;
    temp.architect = {
      name: details.applicant_name,
      email: details.email,
      mobile: details.mobile,
    };
    temp.appliedOn = details.receipt_date;
    temp.applicationId = details.application_number;
    temp.report = drawing;
    let newreport = new ReportOneg(temp);
    let results = await newreport.show();
    setModalType("report");
    setModalData(results);
    setModal(true);
  };
  const viewDemandNoteIssued = (value) => {
    setUserApplicationId(value.id);
    ApplicationService.demandNoteIssued({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("demand");
        setModalData(response);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const viewCELetter = (value) => {
    // type=1->msb_letter,type=2->mom_letter,type=3->ca_letter,type=4->sanction_letter
    // ApplicationUpdateService.pdfLink(value.id, 3)
    ApplicationUpdateService.taPdfDownload({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        // setModalType("ce_letter");
        setModalType("ta_letter");
        setModalData(response);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const viewMOMPDF = (value) => {
    ApplicationUpdateService.momPdfDownload({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        console.log(response);
        // viewReport(response);
        setModalType("mom");
        setModalData(response?.mom_url);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const viewMsbAgengaPDF = (value) => {
    ApplicationUpdateService.msbPdfDownload({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        // console.log(response);
        // viewReport(response);
        setModalType("msb");
        setModalData(response?.agenda_url);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const columns = [
    {
      name: "sl",
      label: "S.No",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{tableMeta.rowIndex + 1}</div>;
        },
      },
    },
    {
      name: "file_no",
      label: "File No",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Link
                to={{
                  pathname: "/application-view",
                  state: list[tableMeta.rowIndex],
                }}
              >
                {value}
              </Link>
            </div>
          );
        },
      },
    },
    {
      name: "application_type",
      label: "Building Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "case_type",
      label: "Case Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "iala_name",
      label: "IALA",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pre_check_report",
      label: "Pre-Check Report",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status > 4
                  ? // == 4
                    "f14 c4 text-center pre-pdf-btn"
                  : "f14 c4 text-center pre-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status > 4 ? false : true}
                onClick={() => viewPreCheckReport(list[tableMeta.rowIndex])}
                className="view-btn"
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "drawing_pdf",
      label: "Drawing PDF",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status > 4
                  ? // == 5
                    "f14 c4 text-center draw-pdf-btn"
                  : "f14 c4 text-center draw-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status > 4 ? false : true}
                onClick={() => viewDrawingPDF(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "secrutiny_report_pdf",
      label: "Scrutiny Report PDF",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status > 4
                  ? "f14 c4 text-center sec-pdf-btn"
                  : "f14 c4 text-center sec-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status > 4 ? false : true}
                onClick={() => viewScrutinyReportPDF(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "demand_note_issued",
      label: "Fee Intimation ",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status > 0 &&
                list[tableMeta.rowIndex].status <= 11
                  ? "f14 c4 text-center pre-pdf-btn opacity-50"
                  : list[tableMeta.rowIndex].status >= 14 &&
                    list[tableMeta.rowIndex].status <= 20
                  ? "f14 c4 text-center pre-pdf-btn opacity-50"
                  : "f14 c4 text-center pre-pdf-btn"
              }
            >
              <button
                className="dwn-btn"
                disabled={
                  list[tableMeta.rowIndex].status > 0 &&
                  list[tableMeta.rowIndex].status <= 11
                    ? true
                    : list[tableMeta.rowIndex].status >= 14 &&
                      list[tableMeta.rowIndex].status <= 20
                    ? true
                    : false
                }
                onClick={() => viewDemandNoteIssued(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "msb_agenda",
      label: "MSB Agenda",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(list[tableMeta.rowIndex].status);
          return (
            <>
              {list[tableMeta.rowIndex].is_high_rise == 1 ? (
                <div
                  className={
                    list[tableMeta.rowIndex].status > 0 &&
                    list[tableMeta.rowIndex].status <= 11
                      ? "f14 c4 text-center msb-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status == 12 ||
                        list[tableMeta.rowIndex].status == 13
                      ? "f14 c4 text-center msb-pdf-btn"
                      : list[tableMeta.rowIndex].status >= 14 &&
                        list[tableMeta.rowIndex].status <= 29
                      ? "f14 c4 text-center msb-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status >= 30 &&
                        list[tableMeta.rowIndex].status <= 37
                      ? "f14 c4 text-center msb-pdf-btn"
                      : list[tableMeta.rowIndex].status >= 38 &&
                        list[tableMeta.rowIndex].status <= 40
                      ? "f14 c4 text-center msb-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status > 40
                      ? "f14 c4 text-center msb-pdf-btn"
                      : "f14 c4 text-center msb-pdf-btn opacity-50"
                  }
                >
                  <button
                    disabled={
                      list[tableMeta.rowIndex].status > 0 &&
                      list[tableMeta.rowIndex].status <= 11
                        ? true
                        : list[tableMeta.rowIndex].status == 12 ||
                          list[tableMeta.rowIndex].status == 13
                        ? false
                        : list[tableMeta.rowIndex].status >= 14 &&
                          list[tableMeta.rowIndex].status <= 29
                        ? true
                        : list[tableMeta.rowIndex].status >= 30 &&
                          list[tableMeta.rowIndex].status <= 37
                        ? false
                        : list[tableMeta.rowIndex].status >= 38 &&
                          list[tableMeta.rowIndex].status <= 40
                        ? true
                        : list[tableMeta.rowIndex].status > 40
                        ? false
                        : true
                    }
                    onClick={() => viewMsbAgengaPDF(list[tableMeta.rowIndex])}
                  >
                    View
                  </button>
                </div>
              ) : (
                " - "
              )}
            </>
          );
        },
      },
    },
    {
      name: "mom",
      label: "MOM",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {list[tableMeta.rowIndex].is_high_rise == 1 ? (
                <div
                  className={
                    list[tableMeta.rowIndex].status > 0 &&
                    list[tableMeta.rowIndex].status <= 11
                      ? "f14 c4 text-center mom-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status == 12 ||
                        list[tableMeta.rowIndex].status == 13
                      ? "f14 c4 text-center mom-pdf-btn"
                      : list[tableMeta.rowIndex].status >= 14 &&
                        list[tableMeta.rowIndex].status <= 29
                      ? "f14 c4 text-center mom-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status >= 30 &&
                        list[tableMeta.rowIndex].status <= 37
                      ? "f14 c4 text-center mom-pdf-btn"
                      : list[tableMeta.rowIndex].status >= 38 &&
                        list[tableMeta.rowIndex].status <= 40
                      ? "f14 c4 text-center mom-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status > 40
                      ? "f14 c4 text-center mom-pdf-btn"
                      : "f14 c4 text-center mom-pdf-btn opacity-50"
                  }
                >
                  <button
                    disabled={
                      list[tableMeta.rowIndex].status > 0 &&
                      list[tableMeta.rowIndex].status <= 11
                        ? true
                        : list[tableMeta.rowIndex].status == 12 ||
                          list[tableMeta.rowIndex].status == 13
                        ? false
                        : list[tableMeta.rowIndex].status >= 14 &&
                          list[tableMeta.rowIndex].status <= 29
                        ? true
                        : list[tableMeta.rowIndex].status >= 30 &&
                          list[tableMeta.rowIndex].status <= 37
                        ? false
                        : list[tableMeta.rowIndex].status >= 38 &&
                          list[tableMeta.rowIndex].status <= 40
                        ? true
                        : list[tableMeta.rowIndex].status > 40
                        ? false
                        : true
                    }
                    // onClick={() => viewMsbAgengaPDF(list[tableMeta.rowIndex])}
                    onClick={() => viewMOMPDF(list[tableMeta.rowIndex])}
                  >
                    View
                  </button>
                </div>
              ) : (
                " - "
              )}{" "}
            </>
          );
        },
      },
    },
    {
      name: "ta_letter",
      label: "TA Letter",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {list[tableMeta.rowIndex].is_high_rise == 1 ? (
                <div
                  className={
                    list[tableMeta.rowIndex].status == 15
                      ? "f14 c4 text-center sec-pdf-btn"
                      : list[tableMeta.rowIndex].status >= 22 &&
                        list[tableMeta.rowIndex].status <= 24
                      ? "f14 c4 text-center sec-pdf-btn"
                      : list[tableMeta.rowIndex].status == 37
                      ? "f14 c4 text-center sec-pdf-btn"
                      : "f14 c4 text-center sec-pdf-btn opacity-50"
                  }
                >
                  <button
                    onClick={() => viewCELetter(list[tableMeta.rowIndex])}
                    disabled={
                      list[tableMeta.rowIndex].status == 15
                        ? false
                        : list[tableMeta.rowIndex].status >= 22 &&
                          list[tableMeta.rowIndex].status <= 24
                        ? false
                        : list[tableMeta.rowIndex].status == 37
                        ? false
                        : true
                    }
                  >
                    View
                  </button>
                </div>
              ) : (
                " - "
              )}{" "}
            </>
          );
        },
      },
    },
    // NEW CHECK
    {
      name: "recepit",
      label: "Recepit",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="f14 c4 text-center date-pdf-btn">
              <button onClick={() => viewRecepit(list[tableMeta.rowIndex])}>
                View
              </button>
            </div>
          );
        },
      },
    },

    {
      name: "created_at",
      label: "Date Of Submission",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  const options = {
    selectableRows: false,
    filter: true,
    filterType: "dropdown",
  };

  return (
    <Col className="rt-dashboard">
      <MUIDataTable
        title={"Demand Note Issued Applications"}
        data={list}
        columns={columns}
        options={options}
      />
      {modal === true ? (
        <>
          <Modal
            show={modal}
            onHide={() => {
              setModal(false);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                {modalType == "report"
                  ? "Report"
                  : modalType == "error"
                  ? "Errors"
                  : modalType == "msb"
                  ? "MSB Agenda"
                  : modalType == "mom"
                  ? "MOM"
                  : modalType == "ta_letter"
                  ? "TA Latter"
                  : modalType == "demand"
                  ? "Fee Intimation"
                  : ""}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {modalType == "report" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "error" ? (
                  <div>
                    <Row>
                      <Col>Errors</Col>
                    </Row>
                    {modalData.map((item, index) => {
                      return (
                        <Row>
                          <Col>{item}</Col>
                        </Row>
                      );
                    })}
                  </div>
                ) : modalType == "demand" ? (
                  //         <div className="draw-pdf-btn">
                  //           <PDFExport
                  //             ref={pdfExportComponent}
                  //             paperSize="A4"
                  //             fileName="Demand-Note"
                  //           >
                  //             <div id="demandnote">
                  //               <div
                  //                 className="p-5"
                  //                 style={{
                  //                   width: "100%",
                  //                   fontFamily: "Verdana, sans-serif",
                  //                 }}
                  //               >
                  //                 <div
                  //                   style={{
                  //                     display: "flex",
                  //                     alignItems: "center",
                  //                   }}
                  //                 >
                  //                   <img src={logoImage}></img>
                  //                   <div style={{ width: "100%" }}>
                  //                     <p className="text-center   mb-1">
                  //                       <b style={{ fontSize: "12px" }}>
                  //                         Telangana State Industrial Infrastructure
                  //                         Corporation
                  //                       </b>
                  //                     </p>
                  //                     <p
                  //                       className="text-center  mb-1"
                  //                       style={{ fontSize: "12px" }}
                  //                     >
                  //                       <b>
                  //                         {modalData?.data?.mandal
                  //                           ? modalData.data.mandal
                  //                           : ""}
                  //                       </b>
                  //                     </p>
                  //                     <p
                  //                       className="text-center mb-1 "
                  //                       style={{
                  //                         textDecoration: "underline",
                  //                         fontSize: "12px",
                  //                       }}
                  //                     >
                  //                       {" "}
                  //                       <b> FEES INTIMATION LETTER</b>
                  //                     </p>
                  //                   </div>
                  //                 </div>

                  //                 <hr />
                  //                 <div>
                  //                   <div
                  //                     style={{
                  //                       display: "flex",
                  //                       justifyContent: "space-between",
                  //                     }}
                  //                   >
                  //                     <p style={{ fontSize: "12px" }}>
                  //                       File No. :{" "}
                  //                       <b style={{ fontSize: "12px" }}>
                  //                         {modalData?.data?.file_no
                  //                           ? modalData.data.file_no
                  //                           : ""}
                  //                       </b>
                  //                     </p>
                  //                     <p style={{ fontSize: "12px" }}>
                  //                       Date :{" "}
                  //                       <b style={{ fontSize: "12px" }}>
                  //                         {modalData?.data?.dated_at
                  //                           ? modalData.data.dated_at
                  //                           : ""}
                  //                       </b>
                  //                     </p>
                  //                   </div>
                  //                 </div>
                  //                 <div className="border border-0 border-secondary p-0 mb-0 w-100">
                  //                   <p
                  //                     className="text-start mb-1 lh-base"
                  //                     style={{ fontSize: "12px" }}
                  //                   >
                  //                     To,
                  //                   </p>
                  //                   <p
                  //                     className="text-start mb-1 lh-base"
                  //                     style={{ fontSize: "12px" }}
                  //                   >
                  //                     <b>
                  //                       {modalData?.user?.first_name
                  //                         ? modalData.user.first_name + " "
                  //                         : ""}
                  //                       {modalData?.user?.middle_name
                  //                         ? modalData.user.middle_name + " "
                  //                         : ""}
                  //                       {modalData?.user?.last_name
                  //                         ? modalData.user.last_name + " "
                  //                         : ""}
                  //                     </b>
                  //                   </p>
                  //                   <p
                  //                     className="text-start mb-1 lh-base"
                  //                     style={{ fontSize: "12px" }}
                  //                   >
                  //                     {modalData?.user?.user_detail?.address
                  //                       ? modalData.user.user_detail.address + ", "
                  //                       : ""}
                  //                     {modalData?.user?.user_detail?.city
                  //                       ? modalData.user.user_detail.city + ", "
                  //                       : ""}
                  //                     {modalData?.user?.user_detail?.state
                  //                       ? modalData.user.user_detail.state
                  //                       : ""}
                  //                   </p>
                  //                   <p
                  //                     className="text-start mb-1 lh-base"
                  //                     style={{ fontSize: "12px" }}
                  //                   >
                  //                     Pin Code -{" "}
                  //                     {modalData?.user?.user_detail?.pin_code
                  //                       ? modalData.user.user_detail.pin_code
                  //                       : ""}{" "}
                  //                   </p>
                  //                 </div>

                  //                 <p
                  //                   className="text-start fw-normal mb-1 mt-2"
                  //                   style={{ fontSize: "12px" }}
                  //                 >
                  //                   Sir / Madam,
                  //                 </p>
                  //                 <div className="ms-4">
                  //                   <p
                  //                     className="fw-normal mb-3 mt-0"
                  //                     style={{ textAlign: "justify", fontSize: "12px" }}
                  //                   >
                  //                     <b>Sub :</b> Building Plan Approval of{" "}
                  //                     <b>
                  //                       {modalData?.data?.building_name
                  //                         ? modalData.data.building_name
                  //                         : ""}{" "}
                  //                     </b>
                  //                     (Building Name) of{" "}
                  //                     {modalData?.user?.title
                  //                       ? modalData.user.title
                  //                       : ""}
                  //                     .
                  //                     <b>
                  //                       {modalData?.user?.first_name
                  //                         ? modalData.user.first_name + " "
                  //                         : ""}
                  //                       {modalData?.user?.middle_name
                  //                         ? modalData.user.middle_name + " "
                  //                         : ""}
                  //                       {modalData?.user?.last_name
                  //                         ? modalData.user.last_name + " "
                  //                         : ""}
                  //                     </b>{" "}
                  //                     in TSIIC Industrial Development Area , in Survey
                  //                     Number
                  //                     <b>
                  //                       {" "}
                  //                       {modalData?.data?.survey_number
                  //                         ? modalData.data.survey_number
                  //                         : ""}
                  //                       ,{" "}
                  //                       {modalData?.data?.village
                  //                         ? modalData.data.village
                  //                         : ""}{" "}
                  //                     </b>{" "}
                  //                     (V),
                  //                     <b>
                  //                       {" "}
                  //                       {modalData?.data?.mandal
                  //                         ? modalData.data.mandal
                  //                         : ""}
                  //                     </b>{" "}
                  //                     (M),{" "}
                  //                     <b>
                  //                       {" "}
                  //                       {modalData?.data?.zone
                  //                         ? modalData.data.zone
                  //                         : ""}{" "}
                  //                       ({/* Cyberabad  */}
                  //                       Zone){" "}
                  //                     </b>
                  //                     {/* District -  */}
                  //                     Sanctioned - Reg.
                  //                   </p>
                  //                   <p
                  //                     className="fw-normal mb-3 mt-0"
                  //                     style={{ textAlign: "justify", fontSize: "12px" }}
                  //                   >
                  //                     <b>Ref : </b>Your Lr. No{" "}
                  //                     {modalData?.data?.file_no
                  //                       ? modalData.data.file_no
                  //                       : ""}
                  //                     , Dt. Date Of Proposal{" "}
                  //                     {modalData?.data?.proposal_date
                  //                       ? modalData.data.proposal_date
                  //                       : ""}
                  //                   </p>
                  //                   <p
                  //                     className="fw-normal mb-3 mt-0"
                  //                     style={{ textAlign: "justify", fontSize: "12px" }}
                  //                   >
                  //                     With reference to your Building application it is
                  //                     to inform that the proposal submitted by you for
                  //                     construction of <b>Commercial </b>on the above
                  //                     said site has been examined. You are requested to
                  //                     pay the balance amount of
                  //                     <b>
                  //                       {" "}
                  //                       Rs.
                  //                       {modalData?.data?.amount
                  //                         ? parseFloat(modalData.data.amount).toFixed(2)
                  //                         : ""}{" "}
                  //                       (Rupees{" "}
                  //                       {modalData?.data?.amount_to_words
                  //                         ? modalData.data.amount_to_words
                  //                         : ""}{" "}
                  //                       Only){" "}
                  //                     </b>
                  //                     towards fee and charges through{" "}
                  //                     <b>online payment</b> within <b>(10) Ten Days </b>
                  //                     from the date of this notice.
                  //                   </p>
                  //                   {modalData?.data?.partial_payment == "yes" && (
                  //                     <div className="d-flex justify-content-center,">
                  //                       <table>
                  //                         <thead>
                  //                           <tr style={{ border: "1px solid #000" }}>
                  //                             <th
                  //                               style={{
                  //                                 border: "1px solid #000",
                  //                                 padding: "5px 10px",
                  //                                 fontSize: "13px",
                  //                                 textAlign: "center",
                  //                               }}
                  //                             >
                  //                               Instalment No.
                  //                             </th>
                  //                             <th
                  //                               style={{
                  //                                 border: "1px solid #000",
                  //                                 padding: "5px 10px",
                  //                                 fontSize: "13px",
                  //                                 textAlign: "center",
                  //                               }}
                  //                             >
                  //                               Date
                  //                             </th>
                  //                             <th
                  //                               style={{
                  //                                 border: "1px solid #000",
                  //                                 padding: "5px 10px",
                  //                                 fontSize: "13px",
                  //                                 textAlign: "center",
                  //                               }}
                  //                             >
                  //                               Amount
                  //                             </th>
                  //                           </tr>
                  //                         </thead>
                  //                         {modalData?.data?.payment_details.length >
                  //                           0 &&
                  //                           modalData?.data?.payment_details.map(
                  //                             (list, index) => {
                  //                               return (
                  //                                 <tbody>
                  //                                   <tr
                  //                                     style={{
                  //                                       border: "1px solid #000",
                  //                                       textAlign: "center",
                  //                                     }}
                  //                                   >
                  //                                     <td
                  //                                       style={{
                  //                                         border: "1px solid #000",
                  //                                         textAlign: "center",
                  //                                         fontSize: "13px",
                  //                                         padding: "5px 10px",
                  //                                       }}
                  //                                     >
                  //                                       {index + 1}
                  //                                     </td>
                  //                                     <td
                  //                                       style={{
                  //                                         border: "1px solid #000",
                  //                                         textAlign: "center",
                  //                                         fontSize: "13px",
                  //                                         padding: "5px 10px",
                  //                                       }}
                  //                                     >
                  //                                       {list.payment_date}
                  //                                     </td>
                  //                                     <td
                  //                                       style={{
                  //                                         border: "1px solid #000",
                  //                                         textAlign: "center",
                  //                                         fontSize: "13px",
                  //                                         padding: "5px 10px",
                  //                                       }}
                  //                                     >
                  //                                       {list.amount}
                  //                                     </td>
                  //                                   </tr>
                  //                                 </tbody>
                  //                               );
                  //                             }
                  //                           )}
                  //                       </table>
                  //                     </div>
                  //                   )}
                  //                   <p
                  //                     style={{
                  //                       fontSize: "12px",
                  //                       fontWeight: "bold",
                  //                       marginTop: "5px",
                  //                       marginBottom: "5px",
                  //                     }}
                  //                   >
                  //                     Additional Conditions
                  //                   </p>
                  //                   <p
                  //                     className="fw-normal mb-1 mt-0"
                  //                     style={{ textAlign: "justify", fontSize: "12px" }}
                  //                   >
                  //                     1. Developer/Builder Licence No. (Xerox Copy)
                  //                   </p>
                  //                   <p
                  //                     className="fw-normal mb-1 mt-0"
                  //                     style={{ textAlign: "justify", fontSize: "12px" }}
                  //                   >
                  //                     2. Risk Insurance Policy of the Building for a
                  //                     period of Six Years
                  //                   </p>
                  //                   <p
                  //                     className="fw-normal mb-1 mt-0"
                  //                     style={{ textAlign: "justify", fontSize: "12px" }}
                  //                   >
                  //                     3. Affidavit/Mortgage deed for 10% of Built up
                  //                     area in favour of ZM&Commissioner, TSIIC IALA,
                  //                     {/* Nanakramguda  */}
                  //                     {" " + modalData?.data?.iala + " "} from the
                  //                     concerned sub-registrar office.
                  //                   </p>
                  //                   {/* <p
                  //   className="fw-normal mb-1 mt-0"
                  //   style={{ textAlign: "justify", fontSize: "12px" }}
                  // >
                  //   4. Affidavit/Mortgage deed for additional 5% of Built up area in
                  //   favour of ZM & COMMISSIONER, TSIIC IALA, Nanakramguda from the
                  //   concerned sub-registrar office for availing 6 instalments to City
                  //   Level Impact Fee as per GO Rt No.278, 06.07.2020 and 4 instalments
                  //   of Development & Processing charges as per GO Ms No. 108, Dt:
                  //   08.07.2020.
                  // </p> */}
                  //                   {/* <p
                  //                     className="fw-normal mb-1 mt-0"
                  //                     style={{ textAlign: "justify", fontSize: "12px" }}
                  //                   >
                  //                     4. As per the recommendations of MSB Committee,
                  //                     the Firm has to construct Under Pass or Foot Over
                  //                     Bridge for movement of occupants of proposed Hotel
                  //                     Building from Hotel to proposed Parking area
                  //                     (which is located on other side of 100' Road) at
                  //                     their own cost before obtaining Occupancy
                  //                     Certificate
                  //                   </p> */}
                  //                   {/* <p
                  //   className="fw-normal mb-1 mt-0"
                  //   style={{ textAlign: "justify", fontSize: "12px" }}
                  // >
                  //   6. The Firm has to Submit balance amount PDC's Cheques for availing
                  //   instalment Facility of Development Charges & City Level Impact Fee
                  // </p> */}
                  //                 </div>
                  //                 <p
                  //                   className="fw-normal mb-1 mt-3"
                  //                   style={{ textAlign: "justify", fontSize: "12px" }}
                  //                 >
                  //                   “This is not a <b> Building Permission</b> and
                  //                   should not commence any construction”. Further
                  //                   action for release of approvals will be taken after
                  //                   fulfilling the above conditions, if the fee and
                  //                   charges and undertakings are not submitted within
                  //                   the stipulated time, your application will be
                  //                   returned / refused without any further intimation.
                  //                 </p>

                  //                 <div
                  //                   className=""
                  //                   style={{ textAlign: "end", fontSize: "12px" }}
                  //                 >
                  //                   <div>
                  //                     <p
                  //                       className=" fw-normal mb-1 mt-0 text-justify"
                  //                       style={{ fontSize: "12px" }}
                  //                     >
                  //                       <br></br>
                  //                       <b>Yours Faithfully</b>
                  //                     </p>
                  //                     <p
                  //                       className=" fw-normal mb-1 mt-0 text-justify"
                  //                       style={{ fontSize: "12px" }}
                  //                     >
                  //                       Name:{" "}
                  //                       {modalData?.data?.commissioner
                  //                         ? modalData.data.commissioner
                  //                         : ""}
                  //                     </p>
                  //                   </div>
                  //                   <div>
                  //                     <p
                  //                       className=" fw-normal mb-1 mt-0 text-justify"
                  //                       style={{ fontSize: "12px" }}
                  //                     >
                  //                       Designation : COMMISSIONER
                  //                     </p>
                  //                     <p
                  //                       className=" fw-normal mb-1 mt-0 text-justify"
                  //                       style={{ fontSize: "12px" }}
                  //                     >
                  //                       Date :{" "}
                  //                       {modalData?.data?.dated_at
                  //                         ? modalData.data.dated_at
                  //                         : ""}
                  //                     </p>
                  //                   </div>
                  //                   <div>
                  //                     <p
                  //                       className=" fw-bold mb-1 mt-3 text-center"
                  //                       style={{
                  //                         fontSize: "12px",
                  //                         justifyContent: "end",
                  //                         display: "flex",
                  //                       }}
                  //                     >
                  //                       {modalData?.data?.mandal
                  //                         ? modalData.data.mandal
                  //                         : ""}{" "}
                  //                       Commissioner
                  //                       <br />
                  //                       TSIIC
                  //                     </p>
                  //                   </div>
                  //                 </div>
                  //               </div>
                  //             </div>
                  //           </PDFExport>
                  //           <div style={{ marginLeft: "45%" }}>
                  //             <button
                  //               className="mt-2"
                  //               onClick={() => {
                  //                 if (pdfExportComponent.current) {
                  //                   pdfExportComponent.current.save();
                  //                 }
                  //               }}
                  //             >
                  //               Download
                  //             </button>
                  //           </div>
                  //         </div>
                  <>
                    <iframe
                      src={modalData?.fee_intimation_url}
                      style={{ height: "500px" }}
                    ></iframe>

                    {/* FOR ONLY DEVELOPERS START */}

                    <div
                      style={{ marginLeft: "45%" }}
                      className="f14 c4  draw-pdf-btn py-3"
                    >
                      <button
                        className=""
                        onClick={() => changeStatus(userApplicationId)}
                      >
                        Change To Payment
                      </button>
                    </div>

                    {/* FOR ONLY DEVELOPERS END */}
                  </>
                ) : modalType == "msb" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "mom" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "ta_letter" ? (
                  <div>
                    <iframe
                      src={modalData?.ta_url ? modalData.ta_url : null}
                      style={{ height: "500px", width: "100%" }}
                    ></iframe>
                  </div>
                ) : modalType == "receipt" ? (
                  <div className="draw-pdf-btn">
                    <PDFExport
                      ref={pdfExportComponent}
                      paperSize="A4"
                      fileName="Payment Receipt"
                    >
                      <div
                        style={{
                          width: "95%",
                          margin: "auto",
                          fontFamily: "Verdana, sans-serif",
                        }}
                      >
                        <div class="hed" style={{ textAlign: "center" }}>
                          <h5
                            style={{
                              fontWeight: "bold",
                              fontSize: "25px",
                              marginBottom: 0,
                            }}
                          >
                            TGIIC
                          </h5>
                          <h6
                            style={{
                              fontSize: "18px",
                              marginBottom: 0,
                              marginTop: "10px",
                              backgroundColor: "#00000012",
                              padding: "2px",
                            }}
                          >
                            {" "}
                            Payment Receipt
                          </h6>
                        </div>
                        <table
                          border="0"
                          cellspacing="0"
                          width="100%"
                          style={{
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            borderBottom: "1px solid #000",

                            borderTop: "1px solid #000",
                          }}
                        >
                          <tbody>
                            <tr>
                              <th
                                style={{
                                  textAlign: "start",
                                  padding: "10px",
                                }}
                              >
                                Receipt No
                              </th>
                              <td style={{ width: " 2%" }}>:</td>
                              <td style={{ textAlgin: "start" }}>
                                {modalData?.receipt_details?.receipt_no
                                  ? modalData?.receipt_details?.receipt_no
                                  : ""}
                              </td>

                              <th
                                style={{
                                  textAlign: "start",
                                  padding: "10px",
                                }}
                              >
                                Receipt Date
                              </th>
                              <td style={{ width: "2%" }}>:</td>
                              <td>
                                {moment(
                                  modalData?.transactions[0]?.created_at
                                ).format("D MMMM, YYYY")}
                              </td>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  textAlign: "start",
                                  padding: "10px",
                                }}
                              >
                                Demand Note No
                              </th>

                              <td style={{ width: "2%" }}>:</td>
                              <td>
                                {modalData?.receipt_details?.demand_note_no
                                  ? modalData?.receipt_details?.demand_note_no
                                  : ""}
                              </td>
                              <th
                                style={{
                                  textAlign: "start",
                                  padding: "10px",
                                }}
                              >
                                BA No
                              </th>
                              <td style={{ width: "2%" }}>:</td>
                              <td>
                                {modalData?.receipt_details?.ba_no
                                  ? modalData?.receipt_details?.ba_no
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          border="0"
                          cellspacing="0"
                          width="100%"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ padding: "10px", width: "30%" }}>
                                {" "}
                                Applicant Name
                              </td>
                              <td style={{ width: "2%" }}>:</td>
                              <td>
                                <b style={{ fontSize: "13px" }}>
                                  {" "}
                                  {userDetails?.first_name
                                    ? userDetails?.first_name
                                    : ""}
                                  {userDetails?.last_name
                                    ? " " + userDetails?.last_name
                                    : ""}
                                </b>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: "10px", width: "30%" }}>
                                {" "}
                                Address of Communication
                              </td>
                              <td>:</td>
                              <td>
                                {" "}
                                {addres}
                                {/* {userDetails?.user_detail?.address
                                ? userDetails?.user_detail?.address
                                : ""}
                              {userDetails?.user_detail?.city
                                ? ", " + userDetails?.user_detail?.city
                                : ""}
                              {userDetails?.user_detail?.state
                                ? ", " + userDetails?.user_detail?.state
                                : ""}
                              {userDetails?.user_detail?.pin_code
                                ? ", " +
                                  userDetails?.user_detail?.pin_code +
                                  "."
                                : ""} */}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table
                          border="1"
                          cellspacing="0"
                          width="100%"
                          style={{
                            fontSize: "13px",
                            padding: "10px",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            borderWidth: "1px",
                            borderColor: "#000",
                            marginBottom: "10px",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  textAlign: "start",
                                  padding: "10px",
                                  width: "20%",
                                  fontSize: "8px",
                                }}
                              >
                                Amount (INR){" "}
                              </td>
                              <td style={{ width: "2%" }}>:</td>
                              <td style={{ width: "50%", fontWeight: 700 }}>
                                {" "}
                                {modalData?.receipt_details?.amount
                                  ? parseFloat(
                                      modalData?.receipt_details?.amount
                                    ).toFixed(2)
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "start",
                                  padding: "10px",
                                  width: "20%",
                                  fontSize: "8px",
                                }}
                              >
                                Amount (In Words)
                              </td>
                              <td style={{ width: "2%" }}>:</td>
                              <td style={{ fontWeight: 700 }}>
                                {" "}
                                {modalData?.receipt_details?.amount_in_words
                                  ? modalData?.receipt_details?.amount_in_words
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "start",
                                  padding: "10px",
                                  fontSize: "8px",
                                }}
                              >
                                Transaction Type
                              </td>
                              <td style={{ width: "2%" }}>:</td>
                              <td style={{ fontSize: "10px", fontWeight: 700 }}>
                                Net Banking / Credit Card / Debit Card
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "start",
                                  padding: "10px",
                                  width: "20%",
                                  fontSize: "8px",
                                }}
                              >
                                Payment Made At
                              </td>
                              <td style={{ width: "2%" }}>:</td>
                              <td style={{ fontWeight: 700 }}>Online</td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  padding: "10px 0px 10px 10px",
                                  fontWeight: 700,
                                }}
                              >
                                Payment Details
                              </td>
                            </tr>
                            {modalData?.transactions.length > 0 &&
                              modalData?.transactions.map((list, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      textAlign: "start",
                                      padding: "10px",
                                      fontSize: "9px",
                                    }}
                                  >
                                    Transaction No
                                  </td>
                                  <td style={{ width: "2%" }}>:</td>
                                  <td
                                    style={{
                                      textAlign: "start",

                                      fontSize: "9px ",
                                      fontWeight: 700,
                                    }}
                                  >
                                    {list.txn_ref_no}
                                  </td>

                                  <td
                                    style={{
                                      textAlign: "end",
                                      padding: "10px",

                                      fontSize: "9px",
                                    }}
                                  >
                                    Date :{" "}
                                    {moment(new Date(list.created_at)).format(
                                      "D MMMM, YYYY"
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {/* <table style={{ width: "100%" }}>
                            <tr
                              style={{
                                fontSize: "18px",
                                backgroundColor: "#00000012",
                              }}
                            >
                              <th
                                style={{
                                  textAlign: "start",
                                  padding: "10px",
                                }}
                                colSpan="6"
                              >
                                Payment Details
                              </th>
                            </tr>
                            <tbody>
                              {modalData?.transactions.length > 0 &&
                                modalData?.transactions.map((list, index) => (
                                  <tr key={index}>
                                    <td
                                      style={{
                                        textAlign: "start",
                                        padding: "10px",
                                        width: "25%",
                                        fontSize: "9px",
                                      }}
                                    >
                                      Transaction No
                                    </td>
                                    <td style={{ width: "2%" }}>:</td>
                                    <td
                                      style={{
                                        textAlign: "start",
                                        width: "15%",
                                        fontSize: "9px ",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {list.txn_ref_no}
                                    </td>

                                    <td
                                      style={{
                                        textAlign: "end",
                                        padding: "10px",
                                        width: "20%",
                                        fontSize: "9px",
                                      }}
                                    >
                                      Date{" "}
                                    </td>
                                    <td style={{ width: "2%" }}>:</td>
                                    <td
                                      style={{
                                        textAlign: "start",
                                        width: "15%",
                                        fontSize: "9px",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {moment(new Date(list.created_at)).format(
                                        "D MMMM, YYYY"
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table> */}
                        <br></br>
                        <br></br>
                        <div class="sig_fs" style={{ float: "right" }}>
                          <h5
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              fontWeight: 700,
                            }}
                          >
                            SIGNATURE OF THE CFC CLERK TGIIC
                          </h5>
                        </div>
                      </div>
                    </PDFExport>
                    <div style={{ marginLeft: "45%" }}>
                      <button
                        className="mt-5"
                        onClick={() => {
                          if (pdfExportComponent.current) {
                            pdfExportComponent.current.save();
                          }
                        }}
                      >
                        Download
                      </button>
                    </div>
                  </div>
                ) : null}
              </Row>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
    </Col>
  );
};
export default DemandNoteIssued;
