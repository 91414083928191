import { PDFExport } from "@progress/kendo-react-pdf";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Pagination,
  Table,
  Dropdown,
  Form,
  ButtonGroup,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ErrorHandler from "../../services/constant/ErrorHandler";
import HighRise from "../../services/constant/HighRise";
import ApplicationUpdateService from "../../services/service/ApplicationUpdateService";
import ApplicationService from "../../services/service/ApplictionService";
import PaymentService from "../../services/service/PaymentService.js";
import UserDetails from "../../services/storage/UserDetails.js";
import ReportOneg from "../Report/thirdchangeReport";

const FinalPaymentCompleted = (props) => {
  const { userDetails } = UserDetails();

  const { highRiseStatus, saveHighRiseStatus } = HighRise();
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [files, setFiles] = useState("");
  const [chalan, setChalan] = useState("");
  const [appID, setAppID] = useState("");
  const [carPolicy, setCarPolicy] = useState("");
  const [otherFiles, setOtherFiles] = useState("");
  const [hR, setHR] = useState("");
  const pdfExportComponent = React.useRef(null);
  const [addres, setAddres] = useState("");
  let address = "";
  useEffect(() => {
    address = userDetails?.user_detail?.address
      ? userDetails?.user_detail?.address
      : null;

    address += userDetails?.user_detail?.city
      ? ", " + userDetails?.user_detail?.city
      : null;
    address += userDetails?.user_detail?.state
      ? ", " + userDetails?.user_detail?.state
      : null;
    address += userDetails?.user_detail?.pin_code
      ? ", " + userDetails?.user_detail?.pin_code + "."
      : null;
    setAddres(address);
  }, []);
  useEffect(() => {
    ApplicationService.getApplicationList({
      type: "zm_demand_note_paid_complete",
    })
      .then((result) => {
        setList(result.data.user_application_zm_demand_note_paid_complete);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);
  const [modalType, setModalType] = useState("");
  const viewPreCheckReport = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("error");
        setModalData(response.precheck_errors);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewDrawingPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("report");
        setModalData(response.scrutiny_report_pdf);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewScrutinyReportPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        // viewReport(response);
        setModalType("report");
        setModalData(response?.scrutiny_report);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewPaymentFiles = (value) => {
    saveHighRiseStatus(value?.is_high_rise ? value.is_high_rise : 0);
    setHR(value?.is_high_rise ? value.is_high_rise : 0);
    ApplicationUpdateService.paymentCompleteFileView({
      user_application_id: value.id,
    })
      .then((result) => {
        let response = result.data;
        setAppID(value.id);
        setModalType("files");
        setModalData(response);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const changeFile = (e) => {
    setFiles(e.target.files);
  };
  const changeFile1 = (e) => {
    setChalan(e.target.files);
  };
  const changeFile2 = (e) => {
    setCarPolicy(e.target.files);
  };

  const changeFile3 = (e) => {
    setOtherFiles(e.target.files);
  };

  const submitFile = () => {
    if (files != "" && chalan != "") {
      var form_data = new FormData();
      form_data.append("user_application_id", appID);
      Object.keys(files).map((item, index) => {
        form_data.append(`mortgage_files[${index}]`, files[item]);
      });
      Object.keys(chalan).map((item, index) => {
        form_data.append(`chellan_files[${index}]`, chalan[item]);
      });
      Object.keys(otherFiles).map((item, index) => {
        form_data.append(`other_files[${index}]`, otherFiles[item]);
      });
      let count = 1;
      if (hR == 1) {
        if (carPolicy != "") {
          Object.keys(carPolicy).map((item, index) => {
            form_data.append(`carpolicy_files[${index}]`, carPolicy[item]);
          });
        } else {
          count = count + 1;
        }
      }
      if (count == 1) {
        ApplicationUpdateService.paymetCompleteFileUpload(form_data)
          .then((result) => {
            let response = result.data;
            alert(response.message);
            setModal(false);
            setModalData("");
            setHR("");
          })
          .catch(function (error) {
            ErrorHandler(error);
          });
      } else {
        alert("Please select Car Policy filles");
      }
    } else {
      alert("Please select all filles");
    }
  };
  const viewReport = async (values) => {
    let temp = {};
    let drawing = JSON.parse(values.rule_engine_json_response);
    let details = values.scrutiny_report_datas;
    let anpdf = values.scrutiny_report_pdf;
    temp.owner = {
      firstName: details.applicant_name,
      lastName: details.applicant_name,
      email: details.email,
      address: details.address,
    };
    temp.mobile = details.mobile;
    temp.surveyNo = details.sector_number;
    temp.doorNo = details.plot_number;
    temp.locality = details.mandal_name;
    temp.village = details.village_name;
    temp.siteaddress = details.site_address;
    temp.useOfTheBuildings = details.land_use_category;
    temp.subuseOfTheBuildings = details.land_subuse_category;
    temp.existingRoadType = details.road_type;
    temp.proposedRoadType = details.land_subuse_category;
    temp.architect = {
      name: details.applicant_name,
      email: details.email,
      mobile: details.mobile,
    };
    temp.appliedOn = details.receipt_date;
    temp.applicationId = details.application_number;
    temp.report = drawing;
    let newreport = new ReportOneg(temp);
    let results = await newreport.show();
    setModalType("report");
    setModalData(results);
    setModal(true);
  };
  const viewRecepit = (value) => {
    PaymentService.payment_receipt({
      user_application_id: value.id,
      type: 2,
    })
      .then((result) => {
        let response = result.data;
        setModalType("receipt");
        setModalData(response);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewDemandNoteIssued = (value) => {
    ApplicationService.demandNoteIssued({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("fees");
        setModalData(response);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const viewCELetter = (value) => {
    // type=1->msb_letter,type=2->mom_letter,type=3->ca_letter,type=4->sanction_letter
    // ApplicationUpdateService.pdfLink(value.id, 3)
    ApplicationUpdateService.taPdfDownload({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        // setModalType("ce_letter");
        setModalType("ta_letter");
        setModalData(response);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const viewMOMPDF = (value) => {
    ApplicationUpdateService.momPdfDownload({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        console.log(response);
        // viewReport(response);
        setModalType("mom");
        setModalData(response?.mom_url);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const viewMsbAgengaPDF = (value) => {
    ApplicationUpdateService.msbPdfDownload({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        // console.log(response);
        // viewReport(response);
        setModalType("msb");
        setModalData(response?.agenda_url);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const columns = [
    {
      name: "sl",
      label: "S.No",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{tableMeta.rowIndex + 1}</div>;
        },
      },
    },
    {
      name: "file_no",
      label: "File No",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Link
                to={{
                  pathname: "/application-view",
                  state: list[tableMeta.rowIndex],
                }}
                onClick={() => {
                  saveHighRiseStatus(
                    list[tableMeta.rowIndex]?.is_high_rise
                      ? list[tableMeta.rowIndex].is_high_rise
                      : 0
                  );
                }}
              >
                {value}
              </Link>
            </div>
          );
        },
      },
    },
    {
      name: "application_type",
      label: "Building Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "case_type",
      label: "Case Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "iala_name",
      label: "IALA",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pre_check_report",
      label: "Pre-Check Report",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status > 4
                  ? "f14 c4 text-center pre-pdf-btn"
                  : "f14 c4 text-center pre-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status > 4 ? false : true}
                onClick={() => viewPreCheckReport(list[tableMeta.rowIndex])}
                className="view-btn"
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "drawing_pdf",
      label: "Drawing PDF",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status > 4
                  ? "f14 c4 text-center draw-pdf-btn"
                  : "f14 c4 text-center draw-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status > 4 ? false : true}
                onClick={() => viewDrawingPDF(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "secrutiny_report_pdf",
      label: "Scrutiny Report PDF",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status > 4
                  ? "f14 c4 text-center sec-pdf-btn"
                  : "f14 c4 text-center sec-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status > 4 ? false : true}
                onClick={() => viewScrutinyReportPDF(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "demand_note_issued",
      label: "Fee Intimation ",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status > 0 &&
                list[tableMeta.rowIndex].status <= 11
                  ? "f14 c4 text-center pre-pdf-btn opacity-50"
                  : list[tableMeta.rowIndex].status >= 14 &&
                    list[tableMeta.rowIndex].status <= 20
                  ? "f14 c4 text-center pre-pdf-btn opacity-50"
                  : "f14 c4 text-center pre-pdf-btn"
              }
            >
              <button
                className="dwn-btn"
                disabled={
                  list[tableMeta.rowIndex].status > 0 &&
                  list[tableMeta.rowIndex].status <= 11
                    ? true
                    : list[tableMeta.rowIndex].status >= 14 &&
                      list[tableMeta.rowIndex].status <= 20
                    ? true
                    : false
                }
                onClick={() => viewDemandNoteIssued(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "msb_agenda",
      label: "MSB Agenda",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(list[tableMeta.rowIndex].status);
          return (
            <>
              {list[tableMeta.rowIndex].is_high_rise == 1 ? (
                <div
                  className={
                    list[tableMeta.rowIndex].status > 0 &&
                    list[tableMeta.rowIndex].status <= 11
                      ? "f14 c4 text-center msb-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status == 12 ||
                        list[tableMeta.rowIndex].status == 13
                      ? "f14 c4 text-center msb-pdf-btn"
                      : list[tableMeta.rowIndex].status >= 14 &&
                        list[tableMeta.rowIndex].status <= 29
                      ? "f14 c4 text-center msb-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status >= 30 &&
                        list[tableMeta.rowIndex].status <= 37
                      ? "f14 c4 text-center msb-pdf-btn"
                      : list[tableMeta.rowIndex].status >= 38 &&
                        list[tableMeta.rowIndex].status <= 40
                      ? "f14 c4 text-center msb-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status > 40
                      ? "f14 c4 text-center msb-pdf-btn"
                      : "f14 c4 text-center msb-pdf-btn opacity-50"
                  }
                >
                  <button
                    disabled={
                      list[tableMeta.rowIndex].status > 0 &&
                      list[tableMeta.rowIndex].status <= 11
                        ? true
                        : list[tableMeta.rowIndex].status == 12 ||
                          list[tableMeta.rowIndex].status == 13
                        ? false
                        : list[tableMeta.rowIndex].status >= 14 &&
                          list[tableMeta.rowIndex].status <= 29
                        ? true
                        : list[tableMeta.rowIndex].status >= 30 &&
                          list[tableMeta.rowIndex].status <= 37
                        ? false
                        : list[tableMeta.rowIndex].status >= 38 &&
                          list[tableMeta.rowIndex].status <= 40
                        ? true
                        : list[tableMeta.rowIndex].status > 40
                        ? false
                        : true
                    }
                    onClick={() => viewMsbAgengaPDF(list[tableMeta.rowIndex])}
                  >
                    View
                  </button>
                </div>
              ) : (
                " - "
              )}
            </>
          );
        },
      },
    },
    {
      name: "mom",
      label: "MOM",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {list[tableMeta.rowIndex].is_high_rise == 1 ? (
                <div
                  className={
                    list[tableMeta.rowIndex].status > 0 &&
                    list[tableMeta.rowIndex].status <= 11
                      ? "f14 c4 text-center mom-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status == 12 ||
                        list[tableMeta.rowIndex].status == 13
                      ? "f14 c4 text-center mom-pdf-btn"
                      : list[tableMeta.rowIndex].status >= 14 &&
                        list[tableMeta.rowIndex].status <= 29
                      ? "f14 c4 text-center mom-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status >= 30 &&
                        list[tableMeta.rowIndex].status <= 37
                      ? "f14 c4 text-center mom-pdf-btn"
                      : list[tableMeta.rowIndex].status >= 38 &&
                        list[tableMeta.rowIndex].status <= 40
                      ? "f14 c4 text-center mom-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status > 40
                      ? "f14 c4 text-center mom-pdf-btn"
                      : "f14 c4 text-center mom-pdf-btn opacity-50"
                  }
                >
                  <button
                    disabled={
                      list[tableMeta.rowIndex].status > 0 &&
                      list[tableMeta.rowIndex].status <= 11
                        ? true
                        : list[tableMeta.rowIndex].status == 12 ||
                          list[tableMeta.rowIndex].status == 13
                        ? false
                        : list[tableMeta.rowIndex].status >= 14 &&
                          list[tableMeta.rowIndex].status <= 29
                        ? true
                        : list[tableMeta.rowIndex].status >= 30 &&
                          list[tableMeta.rowIndex].status <= 37
                        ? false
                        : list[tableMeta.rowIndex].status >= 38 &&
                          list[tableMeta.rowIndex].status <= 40
                        ? true
                        : list[tableMeta.rowIndex].status > 40
                        ? false
                        : true
                    }
                    // onClick={() => viewMsbAgengaPDF(list[tableMeta.rowIndex])}
                    onClick={() => viewMOMPDF(list[tableMeta.rowIndex])}
                  >
                    View
                  </button>
                </div>
              ) : (
                " - "
              )}{" "}
            </>
          );
        },
      },
    },
    {
      name: "ta_letter",
      label: "TA Letter",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {list[tableMeta.rowIndex].is_high_rise == 1 ? (
                <div
                  className={
                    list[tableMeta.rowIndex].status == 15
                      ? "f14 c4 text-center sec-pdf-btn"
                      : list[tableMeta.rowIndex].status >= 22 &&
                        list[tableMeta.rowIndex].status <= 24
                      ? "f14 c4 text-center sec-pdf-btn"
                      : list[tableMeta.rowIndex].status == 37
                      ? "f14 c4 text-center sec-pdf-btn"
                      : "f14 c4 text-center sec-pdf-btn opacity-50"
                  }
                >
                  <button
                    onClick={() => viewCELetter(list[tableMeta.rowIndex])}
                    disabled={
                      list[tableMeta.rowIndex].status == 15
                        ? false
                        : list[tableMeta.rowIndex].status >= 22 &&
                          list[tableMeta.rowIndex].status <= 24
                        ? false
                        : list[tableMeta.rowIndex].status == 37
                        ? false
                        : true
                    }
                  >
                    View
                  </button>
                </div>
              ) : (
                " - "
              )}{" "}
            </>
          );
        },
      },
    },
    {
      name: "files",
      label: "Mortgage deed",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="f14 c4 text-center pre-pdf-btn">
              <button
                className="dwn-btn"
                onClick={() => viewPaymentFiles(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "recepit",
      label: "Recepit",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="f14 c4 text-center date-pdf-btn">
              <button onClick={() => viewRecepit(list[tableMeta.rowIndex])}>
                View
              </button>
            </div>
          );
        },
      },
    },

    {
      name: "created_at",
      label: "Date Of Submission",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  const options = {
    selectableRows: false,
    filter: true,
    filterType: "dropdown",
  };

  return (
    <Col className="rt-dashboard">
      <MUIDataTable
        title={"Final Payment Completed Applications"}
        data={list}
        columns={columns}
        options={options}
      />
      {modal === true ? (
        <>
          <Modal
            show={modal}
            onHide={() => {
              setHR("");
              setModal(false);
              setFiles([]);
              setModalData("");
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                {modalType == "report"
                  ? "Report"
                  : modalType == "files"
                  ? "Mortgage deed Files"
                  : modalType == "receipt"
                  ? "Payment Receipt"
                  : modalType == "fees"
                  ? "Fees Intimation"
                  : modalType == "msb"
                  ? "MSB Agenda"
                  : modalType == "mom"
                  ? "MOM"
                  : modalType == "ta_letter"
                  ? "TA Latter"
                  : "Errors"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {modalType == "report" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "error" ? (
                  <div>
                    <Row>
                      <Col>Errors</Col>
                    </Row>
                    {modalData.map((item, index) => {
                      return (
                        <Row>
                          <Col>{item}</Col>
                        </Row>
                      );
                    })}
                  </div>
                ) : modalType == "files" ? (
                  <div>
                    {modalData?.user_chellan_files.length > 0 &&
                    modalData?.user_mortgage_files.length > 0 ? (
                      <div>
                        <p> Mortgage deed files</p>
                        {modalData.user_mortgage_files.map((item, index) => {
                          return (
                            <div className="mt-2">
                              <iframe
                                src={item.pdf}
                                style={{ height: "500px", width: "100%" }}
                              ></iframe>
                            </div>
                          );
                        })}
                        <p> Challan files</p>
                        {modalData.user_chellan_files.map((item, index) => {
                          return (
                            <div className="mt-2">
                              <iframe
                                src={item.pdf}
                                style={{ height: "500px", width: "100%" }}
                              ></iframe>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="text-center">
                        <div>
                          <p
                            style={{
                              fontSize: "16px",
                              color: "#285fa5",
                            }}
                          >
                            Upload your Mortgage deed files
                          </p>

                          <input
                            style={{
                              fontSize: "16px",
                              padding: "4px",
                              marginBottom: "10px",
                            }}
                            multiple
                            type="file"
                            onChange={(e) => changeFile(e)}
                            accept=".pdf,.PDF"
                          />
                        </div>
                        <div>
                          <p
                            style={{
                              fontSize: "16px",
                              color: "#285fa5",
                            }}
                          >
                            Upload your Challan files
                          </p>

                          <input
                            style={{
                              fontSize: "16px",
                              padding: "4px",
                              marginBottom: "10px",
                            }}
                            multiple
                            type="file"
                            onChange={(e) => changeFile1(e)}
                            accept=".pdf,.PDF"
                          />
                        </div>
                        {hR == 1 && (
                          <div>
                            <p
                              style={{
                                fontSize: "16px",
                                color: "#285fa5",
                              }}
                            >
                              Car policy files
                            </p>

                            <input
                              style={{
                                fontSize: "16px",
                                padding: "4px",
                                marginBottom: "10px",
                              }}
                              multiple
                              type="file"
                              onChange={(e) => changeFile2(e)}
                              accept=".pdf,.PDF"
                            />
                          </div>
                        )}
                        <div>
                          <p
                            style={{
                              fontSize: "16px",
                              color: "#285fa5",
                            }}
                          >
                            Upload Other files
                          </p>

                          <input
                            style={{
                              fontSize: "16px",
                              padding: "4px",
                              marginBottom: "10px",
                            }}
                            multiple
                            type="file"
                            onChange={(e) => changeFile3(e)}
                            accept=".pdf,.PDF"
                          />
                        </div>
                        <div className="sec-pdf-btn">
                          <button className="dwn-btn" onClick={submitFile}>
                            Upload
                          </button>
                        </div>
                        {/* {files.length > 0 &&
                                                            Object.keys(files).map((item) => {
                                                                return (
                                                                    <div className="mt-2">
                                                                        <iframe src={URL.createObjectURL(files[item])} style={{ height: '500px', width: '100%' }} ></iframe>
                                                                    </div>
                                                                )
                                                            })
                                                        } */}
                      </div>
                    )}
                  </div>
                ) : modalType == "fees" ? (
                  <iframe
                    src={modalData?.fee_intimation_url}
                    style={{ height: "500px" }}
                  ></iframe>
                ) : modalType == "msb" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "mom" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "ta_letter" ? (
                  <div>
                    <iframe
                      src={modalData?.ta_url ? modalData.ta_url : null}
                      style={{ height: "500px", width: "100%" }}
                    ></iframe>
                  </div>
                ) : (
                  modalType == "receipt" && (
                    <div className="draw-pdf-btn">
                      <PDFExport
                        ref={pdfExportComponent}
                        paperSize="A4"
                        fileName="Payment Receipt"
                      >
                        <div
                          style={{
                            width: "95%",
                            margin: "auto",
                            fontFamily: "Verdana, sans-serif",
                          }}
                        >
                          <div class="hed" style={{ textAlign: "center" }}>
                            <h5
                              style={{
                                fontWeight: "bold",
                                fontSize: "25px",
                                marginBottom: 0,
                              }}
                            >
                              TGIIC
                            </h5>
                            <h6
                              style={{
                                fontSize: "18px",
                                marginBottom: 0,
                                marginTop: "10px",
                                backgroundColor: "#00000012",
                                padding: "2px",
                              }}
                            >
                              {" "}
                              Payment Receipt
                            </h6>
                          </div>
                          <table
                            border="0"
                            cellspacing="0"
                            width="100%"
                            style={{
                              fontSize: "13px",
                              fontStyle: "normal",
                              fontWeight: "normal",
                              borderBottom: "1px solid #000",

                              borderTop: "1px solid #000",
                            }}
                          >
                            <tbody>
                              <tr>
                                <th
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                  }}
                                >
                                  Receipt No
                                </th>
                                <td style={{ width: " 2%" }}>:</td>
                                <td style={{ textAlgin: "start" }}>
                                  {modalData?.receipt_details?.receipt_no
                                    ? modalData?.receipt_details?.receipt_no
                                    : ""}
                                </td>

                                <th
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                  }}
                                >
                                  Receipt Date
                                </th>
                                <td style={{ width: "2%" }}>:</td>
                                <td>
                                  {moment(new Date()).format("D MMMM, YYYY")}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                  }}
                                >
                                  Demand Note No
                                </th>

                                <td style={{ width: "2%" }}>:</td>
                                <td>
                                  {modalData?.receipt_details?.demand_note_no
                                    ? modalData?.receipt_details?.demand_note_no
                                    : ""}
                                </td>
                                <th
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                  }}
                                >
                                  BA No
                                </th>
                                <td style={{ width: "2%" }}>:</td>
                                <td>
                                  {modalData?.receipt_details?.ba_no
                                    ? modalData?.receipt_details?.ba_no
                                    : ""}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table
                            border="0"
                            cellspacing="0"
                            width="100%"
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td style={{ padding: "10px", width: "30%" }}>
                                  {" "}
                                  Applicant Name
                                </td>
                                <td style={{ width: "2%" }}>:</td>
                                <td>
                                  <b style={{ fontSize: "13px" }}>
                                    {" "}
                                    {userDetails?.first_name
                                      ? userDetails?.first_name
                                      : ""}
                                    {userDetails?.last_name
                                      ? " " + userDetails?.last_name
                                      : ""}
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "10px", width: "30%" }}>
                                  {" "}
                                  Address of Communication
                                </td>
                                <td>:</td>
                                <td>
                                  {" "}
                                  {addres}
                                  {/* {userDetails?.user_detail?.address
                                ? userDetails?.user_detail?.address
                                : ""}
                              {userDetails?.user_detail?.city
                                ? ", " + userDetails?.user_detail?.city
                                : ""}
                              {userDetails?.user_detail?.state
                                ? ", " + userDetails?.user_detail?.state
                                : ""}
                              {userDetails?.user_detail?.pin_code
                                ? ", " +
                                  userDetails?.user_detail?.pin_code +
                                  "."
                                : ""} */}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table
                            border="1"
                            cellspacing="0"
                            width="100%"
                            style={{
                              fontSize: "13px",
                              padding: "10px",
                              fontStyle: "normal",
                              fontWeight: "normal",
                              borderWidth: "1px",
                              borderColor: "#000",
                              marginBottom: "10px",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                    width: "20%",
                                    fontSize: "8px",
                                  }}
                                >
                                  Amount (INR){" "}
                                </td>
                                <td style={{ width: "2%" }}>:</td>
                                <td style={{ width: "50%", fontWeight: 700 }}>
                                  {" "}
                                  {modalData?.receipt_details?.amount
                                    ? parseFloat(
                                        modalData?.receipt_details?.amount
                                      ).toFixed(2)
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                    width: "20%",
                                    fontSize: "8px",
                                  }}
                                >
                                  Amount (In Words)
                                </td>
                                <td style={{ width: "2%" }}>:</td>
                                <td style={{ fontWeight: 700 }}>
                                  {" "}
                                  {modalData?.receipt_details?.amount_in_words
                                    ? modalData?.receipt_details
                                        ?.amount_in_words
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                    fontSize: "8px",
                                  }}
                                >
                                  Transaction Type
                                </td>
                                <td style={{ width: "2%" }}>:</td>
                                <td
                                  style={{ fontSize: "10px", fontWeight: 700 }}
                                >
                                  Net Banking / Credit Card / Debit Card
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                    width: "20%",
                                    fontSize: "8px",
                                  }}
                                >
                                  Payment Made At
                                </td>
                                <td style={{ width: "2%" }}>:</td>
                                <td style={{ fontWeight: 700 }}>Online</td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: "10px 0px 10px 10px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Payment Details
                                </td>
                              </tr>
                              {modalData?.transactions.length > 0 &&
                                modalData?.transactions.map((list, index) => (
                                  <tr key={index}>
                                    <td
                                      style={{
                                        textAlign: "start",
                                        padding: "10px",
                                        fontSize: "9px",
                                      }}
                                    >
                                      Transaction No
                                    </td>
                                    <td style={{ width: "2%" }}>:</td>
                                    <td
                                      style={{
                                        textAlign: "start",

                                        fontSize: "9px ",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {list.txn_ref_no}
                                    </td>

                                    <td
                                      style={{
                                        textAlign: "end",
                                        padding: "10px",

                                        fontSize: "9px",
                                      }}
                                    >
                                      Date :{" "}
                                      {moment(new Date(list.created_at)).format(
                                        "D MMMM, YYYY"
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {/* <table style={{ width: "100%" }}>
                            <tr
                              style={{
                                fontSize: "18px",
                                backgroundColor: "#00000012",
                              }}
                            >
                              <th
                                style={{
                                  textAlign: "start",
                                  padding: "10px",
                                }}
                                colSpan="6"
                              >
                                Payment Details
                              </th>
                            </tr>
                            <tbody>
                              {modalData?.transactions.length > 0 &&
                                modalData?.transactions.map((list, index) => (
                                  <tr key={index}>
                                    <td
                                      style={{
                                        textAlign: "start",
                                        padding: "10px",
                                        width: "25%",
                                        fontSize: "9px",
                                      }}
                                    >
                                      Transaction No
                                    </td>
                                    <td style={{ width: "2%" }}>:</td>
                                    <td
                                      style={{
                                        textAlign: "start",
                                        width: "15%",
                                        fontSize: "9px ",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {list.txn_ref_no}
                                    </td>

                                    <td
                                      style={{
                                        textAlign: "end",
                                        padding: "10px",
                                        width: "20%",
                                        fontSize: "9px",
                                      }}
                                    >
                                      Date{" "}
                                    </td>
                                    <td style={{ width: "2%" }}>:</td>
                                    <td
                                      style={{
                                        textAlign: "start",
                                        width: "15%",
                                        fontSize: "9px",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {moment(new Date(list.created_at)).format(
                                        "D MMMM, YYYY"
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table> */}
                          <br></br>
                          <br></br>
                          <div class="sig_fs" style={{ float: "right" }}>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "14px",
                                fontWeight: 700,
                              }}
                            >
                              SIGNATURE OF THE CFC CLERK TGIIC
                            </h5>
                          </div>
                        </div>
                      </PDFExport>
                      <div style={{ marginLeft: "45%" }}>
                        <button
                          className="mt-5"
                          onClick={() => {
                            if (pdfExportComponent.current) {
                              pdfExportComponent.current.save();
                            }
                          }}
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  )
                )}
              </Row>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
    </Col>
  );
};
export default FinalPaymentCompleted;
