import React from "react";
import { Switch, Route, HashRouter } from "react-router-dom";
import RegistrationForm from "../screens/form/RegistrationForm";
import Signup from "../screens/Signup";
import CitizenForm from "../screens/form/CitizenForm";
import DashBoard from "../screens/DashBoard";
import DraftApp from "../component/DraftAppRit";
import ApplicPermission from "../screens/ApplicPermission";
import ApplicOccupancy from "../screens/ApplicOccupancy";
import UserApplication from "../screens/UserApplication";

import ProceedingIssuedStatus from "../screens/ProceedingIssuedStatus";
import Rejected from "../screens/Rejected";
import Shortfalls from "../screens/Shortfalls";
import ApprovedFiles from "../screens/ApprovedFiles";

import ProceedingIssuedProgress from "../screens/ProceedingIssuedProgress";
import IntimateWorkProgress from "../screens/IntimateWorkProgress";
import InspectionScheduleAndReport from "../screens/InspectionScheduleAndReport";
import Applicationsearch from "../screens/Applicationsearch";
import OnlinePayment from "../screens/OnlinePayment";
import Profile from "../screens/Profile";
import ChangePassword from "../screens/ChangePassword";
import DraftApplication from "../screens/DraftApplication";
import useToken from "../services/storage/useToken";
import { createBrowserHistory } from "history";
import httpClient from "../services/constant/httpClient";
import SubmittedApplications from "../screens/SubmittedApplications";
import SubmissionPending from "../screens/SubmissionPending";
import GeneralInspectionReportView from "../component/dashboard/GeneralInspectionReportView";
import JoinInspectionReportView from "../component/dashboard/JoinInspectionReportView";
import ViewApplication from "../component/ApplicationViewShortfall/ViewApplication";
import SelectTab from "../screens/SelectTab";
import ApplicOccupancyEdit from "../screens/ApplicOccupancyEdit";
import ApplicOccupancyView from "../screens/ApplicOccupancyView";
import ActivityLogList from "../component/ActivityLogList";
import ActivityDashboardList from "../component/ActivitDashboardList";
export const history = createBrowserHistory();

const Routes = (props) => {
  const { token, setToken } = useToken();
  // setToken('92|KoJbQyuj5bONzXTGVNErmanFaC461YQyOFEE2C6I');
  if (token) {
    httpClient.defaults.headers.common["Authorization"] =
      `Bearer ${token}` || "";
  }
  return (
    <HashRouter history={history}>
      <React.Fragment>
        <Switch>
          <Route exact path="/" component={Signup}>
            {token ? <DraftApplication /> : <Signup setToken={setToken} />}
          </Route>
          <Route exact path="/select-apply-for" component={SelectTab}>
            <SelectTab />
          </Route>
          <Route
            exact
            path="/professional/registration"
            component={RegistrationForm}
          />
          <Route exact path="/citizen/registration" component={CitizenForm} />
          <Route exact path="/draft-application" component={DraftApplication} />
          <Route exact path="/dashboard" component={DashBoard} />
          <Route exact path="/activity-log" component={ActivityLogList} />
          <Route
            exact
            path="/activity-dashboard"
            component={ActivityDashboardList}
          />
          <Route
            exact
            path="/submitted-application"
            component={SubmittedApplications}
          />
          <Route
            exact
            path="/submission-pending"
            component={SubmissionPending}
          />
          <Route
            exact
            path="/application-permission"
            component={ApplicPermission}
          />
          <Route
            exact
            path="/application-occupancy"
            component={ApplicOccupancy}
          />
          <Route exact path="/occupancy-edit" component={ApplicOccupancyEdit} />
          <Route exact path="/occupancy-view" component={ApplicOccupancyView} />
          <Route exact path="/user-application" component={UserApplication} />
          <Route exact path="/application-view" component={ViewApplication} />

          <Route
            exact
            path="/proceeding-issued-status"
            component={ProceedingIssuedStatus}
          />
          <Route exact path="/rejected" component={Rejected} />
          <Route exact path="/shortfalls" component={Shortfalls} />
          <Route exact path="/approved-files" component={ApprovedFiles} />

          <Route
            exact
            path="/proceeding-issued-progress"
            component={ProceedingIssuedProgress}
          />
          <Route
            exact
            path="/intimate-work-progress"
            component={IntimateWorkProgress}
          />
          <Route
            exact
            path="/inspection-schedule-report"
            component={InspectionScheduleAndReport}
          />

          <Route
            exact
            path="/application-search"
            component={Applicationsearch}
          />
          <Route exact path="/online-payment" component={OnlinePayment} />

          <Route exact path="/profile" component={Profile} />
          <Route exact path="/change-password" component={ChangePassword} />

          <Route
            exact
            path="/general-inspection-report-view"
            component={GeneralInspectionReportView}
          />
          <Route
            exact
            path="/join-inspection-report-view"
            component={JoinInspectionReportView}
          />
        </Switch>
      </React.Fragment>
    </HashRouter>
  );
};

export default Routes;
