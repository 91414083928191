import React from "react";
import httpClient from "../constant/httpClient";
import { URL } from "../constant/URL";

const getZoneList = () => {
  return httpClient.get(URL.ZONE_LIST);
};
const getZoneSubList = (id) => {
  return httpClient.get(URL.ZONE_SUB_LIST + id);
};
const userApplication = (payload) => {
  return httpClient.post(URL.USER_APPLICATION, payload);
};
const userApplicantInfo = (payload) => {
  return httpClient.post(URL.APPLICANT_INFO, payload);
};

const getTypeNameList = (payload) => {
  return httpClient.post(URL.TYPE_NAME_LIST, payload);
};
const getTypeNameDetails = (payload) => {
  return httpClient.post(URL.TYPE_NAME_DETAILS, payload);
};
const getCheckList = () => {
  return httpClient.get(URL.CHECK_LISTS);
};
const postCheckList = (payload) => {
  return httpClient.post(URL.CHECK_LIST, payload);
};
const getProposedActivity = (id) => {
  return httpClient.get(URL.GET_PROPOSED_ACTIVITY + "?proposed_use=" + id);
};
const getPlotLocations = (id) => {
  return httpClient.get(URL.GET_PLOT_LOCATIONS + "?plot_location_id=" + id);
};
const savePlotDetails = (payload) => {
  return httpClient.post(URL.SAVE_PLOT_DETAILS, payload);
};
const getSiteDetailsList = () => {
  return httpClient.post(URL.SITE_DETAILS);
};
const saveSiteDetails = (payload) => {
  return httpClient.post(URL.SAVE_SITE_DETAILS, payload);
};
const getUseList = () => {
  return httpClient.get(URL.USE_LIST);
};
const getSubUseList = (id) => {
  return httpClient.get(URL.SUB_USE_LIST + id);
};
const saveBuildingDetails = (payload) => {
  return httpClient.post(URL.BUILDING_DETAIL, payload);
};
const saveApplyNOC = (payload) => {
  return httpClient.post(URL.APPLY_NOC, payload);
};
const getDocumentsList = () => {
  return httpClient.post(URL.DOCUMENT_LIST);
};
const saveDocuments = (payload) => {
  return httpClient.post(URL.DOCUMENT_UPLOAD, payload);
};
const saveDrawing = (payload) => {
  return httpClient.post(URL.DRAWING_UPLOAD, payload);
};
const viewApplication = (payload) => {
  return httpClient.post(URL.APPLICATION_VIEW, payload);
};
const saveBoundaries = (payload) => {
  return httpClient.post(URL.BOUNDRIES, payload);
};
const saveFloors = (payload) => {
  return httpClient.post(URL.FLOORS, payload);
};
const getFloors = (payload) => {
  return httpClient.post(URL.FLOORS_VIEW, payload);
};
const getApplicationList = (payload) => {
  return httpClient.post(URL.APPLICATION_LIST, payload);
};
const demandNoteIssued = (payload) => {
  return httpClient.post(URL.DEMAND_NOTE_ISSUED, payload);
};

// DELETE METHOD FOR BUILDING DETAILS

const deleteBuilding = (payload) => {
  return httpClient.post(URL.BUILDING_DETAIL_DELETE + "/delete", payload);
};

// OCCUPANCY
const occupancyGeneralInfoAdd = (payload) => {
  return httpClient.post(URL.OCCUPANCY_GENERAL_INFO_STORE, payload);
};

const occupancyGeneralInfoEdit = (payload, id) => {
  return httpClient.post(URL.OCCUPANCY_GENERAL_INFO_EDIT + id, payload);
};

const occupancyProposedUseAdd = (payload) => {
  return httpClient.post(URL.PROPOSED_USE_STORE, payload);
};

const occupancyProposedUseEdit = (payload, id) => {
  return httpClient.post(URL.PROPOSED_USE_EDIT + id, payload);
};

const occupancyBuildingStore = (payload) => {
  return httpClient.post(URL.BUILDING_DETAIL_STORE_OCCUPANCY, payload);
};

const occupancyBuildingEdit = (payload, id) => {
  return httpClient.post(URL.BUILDING_DETAIL_EDIT_OCCUPANCY + id, payload);
};

const occupancyDocumentList = (payload) => {
  return httpClient.post(URL.OCCUPANCY_DOCUMENT_LIST, payload);
};

const occupancyDocumentAdd = (payload) => {
  return httpClient.post(URL.OCCUPANCY_DOCUMENT_STORE, payload);
};

const occupancyDocumentShortFallStore = (payload) => {
  return httpClient.post(URL.OCCUPANCY_SITE_DETAILS_SHORTFALL_STORE, payload);
};

const occupancyDetails = (payload) => {
  return httpClient.post(URL.USER_OCCUPANCY_DETAILS, payload);
};

const occupancyUserDetails = (payload) => {
  return httpClient.post(URL.USER_APPLICATION_DETAILS, payload);
};

const get_occupancy_link = (payload) => {
  return httpClient.post(URL.DASHBOARD_OCCUPANCY, payload);
};

const get_occupancy_view = (id) => {
  return httpClient.get(URL.OCCUPANCY_VIEW + id);
};

const occupancy_site_store = (payload) => {
  return httpClient.post(URL.OCCUPANCY_SITE_DETAILS_STORE, payload);
};

const occupancy_site_edit = (payload, id) => {
  return httpClient.post(URL.OCCUPANCY_SITE_DETAILS_EDIT + id, payload);
};

const occupancy_intimation_file_download = (payload) => {
  return httpClient.post(
    URL.OCCUPANCY_SITE_DETAILS_INTIMATION_FEE_LETTER,
    payload
  );
};

const occupancy_fee_download = (payload) => {
  return httpClient.post(URL.OCCUPANCY_FEE_DOWNLOAD, payload);
};

const occupancy_fee_upload = (payload) => {
  return httpClient.post(URL.OCCUPANCY_FEE_UPLOAD, payload);
};

const user_application_activities = (id) => {
  return httpClient.get(URL.USER_APPLICATION_ACTIVITIES + id);
};

const user_application_activities_dash = (payload) => {
  return httpClient.post(URL.USER_APPLICATION_ACTIVITIES_DASH, payload);
};

const ApplicationService = {
  deleteBuilding,
  getZoneList,
  getZoneSubList,
  userApplication,
  userApplicantInfo,
  getTypeNameList,
  getTypeNameDetails,
  getCheckList,
  postCheckList,
  getProposedActivity,
  getPlotLocations,
  savePlotDetails,
  getSiteDetailsList,
  saveSiteDetails,
  getUseList,
  getSubUseList,
  saveBuildingDetails,
  saveApplyNOC,
  getDocumentsList,
  saveDocuments,
  saveDrawing,
  viewApplication,
  saveBoundaries,
  saveFloors,
  getFloors,
  getApplicationList,
  demandNoteIssued,
  user_application_activities,
  user_application_activities_dash,

  // OCCUPANCY
  occupancyGeneralInfoAdd,
  occupancyGeneralInfoEdit,
  occupancyProposedUseAdd,
  occupancyProposedUseEdit,
  occupancyBuildingStore,
  occupancyBuildingEdit,
  occupancyDocumentList,
  occupancyDocumentAdd,
  occupancyDocumentShortFallStore,
  occupancyDetails,
  occupancyUserDetails,
  get_occupancy_link,
  get_occupancy_view,
  occupancy_site_store,
  occupancy_site_edit,
  occupancy_intimation_file_download,
  occupancy_fee_download,
  occupancy_fee_upload,
};
export default ApplicationService;
