export const BASE_URL =
  //  "http://192.168.1.34/autometry_scripts/public";
  // "http://tsiicscript.abpas.co.in/index.php";
  //  < = this live
  "http://tsiicscript.autometrybpas.co.in/public/";
// "http://192.168.1.19/autometry_scripts/public/";
//  < = development
// "http://tgiicscript.abpas.co.in/index.php"; //TGIIC URL
// "http://192.168.1.25/autometry_scripts/public";
// "http://tsiicscript.autometrybpas.co.in/public/";
// "http://tsiicscript.abpas.co.in/public/index.php/";
// 'http://192.168.1.203/autometry_scripts/public/';
// 'http://192.168.1.202/autometry_scripts/public/';
export const URL = {
  LOGIN: "api/user-login",
  REGISTER: "api/user-register",
  USER_NAME_CHECK: "api/user-check",
  ZONE_LIST: "crmapi/placelist",
  ZONE_SUB_LIST: "crmapi/placeparentlist/", // id has to pass in url
  USER_APPLICATION: "api/user-application",
  APPLICANT_INFO: "api/user-application/applicant-info",
  TYPE_NAME_LIST: "api/ltp-users",
  TYPE_NAME_DETAILS: "api/ltp-user",
  CHECK_LIST: "api/user-application/checklist",
  CHECK_LISTS: "api/application-checklists",
  GET_PROPOSED_ACTIVITY: "api/proposed-activities",
  GET_PLOT_LOCATIONS: "api/plot-locations",
  SAVE_PLOT_DETAILS: "api/user-application/plot-details",
  SAVE_SITE_DETAILS: "api/user-application/site_details",
  SITE_DETAILS: "api/site-detail-question-list",
  DRAFT_LIST: "api/user-application-draft-list",
  PENDING_LIST: "api/user-application-submission-pending-list",
  SUBMITTED_LIST: "api/user-application-submitted-application-list",
  USE_LIST: "crmapi/building_detaillist",
  SUB_USE_LIST: "crmapi/building_detailparentlist/", // id has to pass in URL
  BUILDING_DETAIL: "api/user-application/building-details",
  BUILDING_DETAIL_DELETE: "api/user-application-building-details",
  APPLY_NOC: "api/user-application/noc",
  DOCUMENT_LIST: "api/application/document/list",
  DOCUMENT_UPLOAD: "api/user-application/document",
  DRAWING_UPLOAD: "api/user-application/drawing",
  APPLICATION_VIEW: "api/user-application-edit/view",
  BOUNDRIES: "api/user-application/boundry-image",
  FLOORS: "api/user-application/building-floors",
  FLOORS_VIEW: "api/user-application-floor-detail/view",
  GENERALINFORMATION_UPDATE: "api/user-application/update/", //application_id has pass in url
  PLOT_DETAILS_UPDATE: "api/user-application/plot-details/update/", //application_id has pass in url
  BUILDING_DETAIL_UPDATE: "api/user-application/building-details/update/", //user_application_building_detail_id has to pass in url
  FLOORS_UPDATE: "api/user-application/building-floors/update/", //user_application_building_floor_id
  NOC_UPDATE: "api/user-application-noc/update",
  DOCUMENT_UPDATE: "api/user-application-document/update",
  DRAWING_UPDATE: "api/user-application-drawing/update/", //drawing_id has to pass in url
  REPORT_DETAIL: "api/user-application/scrutiny-report",
  SANCTION_DIGITAL_LETTER: "crmapi/user-digital-signature-view/",
  DASHBOARD: "api/user-dashboard",
  PAYMENT_INITIATE: "api/user-payment-initiate",
  APPLICATION_LIST: "api/user-application-list",
  DRAWING_FILE_CHANGE: "api/user-application-drawing-shortfall/update/", //drawing_id has to pass in url
  DOCUMENT_CHANGE: "api/user-application-document-shortfall/update",
  NOC_CHANGE: "api/user-application-noc-shortfall/update/", //noc_document_id has to pass in url
  SITE_DETAILS_CHANGE: "api/user-application-site-detail-shortfall/update/", //user_application_site_detail_id has to pass in url
  DOWNLOAD_LINKS: "api/application-drawings",
  DEMAND_NOTE_ISSUED: "api/user-demand-note-payment",
  SANCTION_LETTER: "api/user-application-sanction",
  DEMAND_NOTE_PAYMENT_FILES_UPLOAD:
    "api/user-application-mortgage-files-upload",
  DEMAND_NOTE_PAYMENT_FILES_REUPLOAD:
    "api/user-application-mortgage-files-reupload/", //id has to pass in url
  DEMAND_NOTE_PAYMENT_FILES_VIEW: "api/user-application-mortgage-files-list",
  SANCTION_LETTER_ACCEPT: "api/user-application/sanction-accept",
  PDF_LINK: "api/convert_word_to_pdf/", //id and type has to pass in url
  SANCTION_PDF_LINK: "crmapi/user-application/sanction-pdf", //id and type has to pass in url
  PAYMENT_RECEIPT: "api/payment-receipt",
  MSB_PDF_DOWNLOAD: "crmapi/user-application/msb-pdf",
  TA_PDF_DOWNLOAD: "crmapi/user-application/ta-pdf",
  MOM_PDF_DOWNLOAD: "crmapi/user-application/mom-pdf",
  SINGLE_PDF_DOWNLOAD: "crmapi/user-application-download-pdf/",
  USER_APPLICATION_ACTIVITIES: "crmapi/user-application-activities/",
  USER_APPLICATION_ACTIVITIES_DASH:
    "crmapi/application-activities/user-dashboard",

  CHANGE_PAYMENT_COMPLETED: "api/change-payment-completed/", // develop

  // OCCUPANCY
  OCCUPANCY_GENERAL_INFO_STORE: "api/user-occupancy-general-info-store",
  OCCUPANCY_GENERAL_INFO_EDIT: "api/user-occupancy-general-info-edit/",
  PROPOSED_USE_STORE: "api/user-occupancy-proposed-use-store",
  PROPOSED_USE_EDIT: "/api/user-occupancy-proposed-use-edit/",
  BUILDING_DETAIL_STORE_OCCUPANCY: "api/user-occupancy-building-detail-store",
  BUILDING_DETAIL_EDIT_OCCUPANCY: "api/user-occupancy-building-detail-edit/",
  OCCUPANCY_DOCUMENT_LIST: "api/user-occupancy-document-list",
  OCCUPANCY_DOCUMENT_STORE: "api/user-occupancy-document-store",
  USER_APPLICATION_DETAILS: "api/user-application/detail",
  USER_OCCUPANCY_DETAILS: "api/user-occupancy-detail",
  DASHBOARD_OCCUPANCY: "api/user-occupancy-list",
  DASHBOARD_OCCUPANCY_LIST: "api/user-occupancy-dashboard",
  OCCUPANCY_VIEW: "api/user-occupancy-view/",
  OCCUPANCY_SITE_DETAILS_STORE: "api/user-occupancy-site-detail-store",
  OCCUPANCY_SITE_DETAILS_EDIT: "api/user-occupancy-site-detail-store",
  OCCUPANCY_SITE_DETAILS_SHORTFALL_STORE:
    "api/user-occupancy-document-shortfall-store",
  OCCUPANCY_SITE_DETAILS_INTIMATION_FEE_LETTER:
    "crmapi/occupancy-demand-note-download",
  OCCUPANCY_REJECTION_LETTER_DOWNLOAD: "api/occupancy-rejection-letter",
  OCCUPANCY_CERTIFICATE_DOWNLOAD: "api/occupancy-certificate-view",
  OCCUPANCY_DEMOND_NOTE_PAYMENT_DOWNLOAD: "api/occupancy-demand-note-payment",
  OCCUPANCY_USER_ACCOUNT_DETAIL: "api/user-account-detail",
  OCCUPANCY_FEE_DOWNLOAD: "api/occupancy-fee-receipt-download",
  OCCUPANCY_FEE_UPLOAD: "api/occupancy-fee-receipt-upload",
};
