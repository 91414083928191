import React, { useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Pagination,
  Table,
  Dropdown,
  Form,
  ButtonGroup,
} from "react-bootstrap";
import Header from "../component/Header";
import Pdf from "../assets/images/CommonAffidavitUndertaking.pdf";
import Menubar from "../component/Menubar";
import Sidebar from "../component/Sidebar";
import DraftAppRit from "../component/DraftAppRit";
import { Link, useHistory } from "react-router-dom";
import DashboardLink from "../component/DashboardLink";
import HighRise from "../services/constant/HighRise";

const DraftApplication = () => {
  const { saveHighRiseStatus } = HighRise();
  // const history = useHistory();
  useEffect(() => {
    saveHighRiseStatus(0);
    // window.location.href = '/draft-application';
  }, []);
  return (
    <Col
      className="rt-dashboard"
      style={{
        background: "#edf0f2",
      }}
    >
      <Header />

      <DashboardLink />
      {/* <DraftAppRit /> */}
    </Col>
  );
};

export default DraftApplication;
