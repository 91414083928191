import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Pagination,
  Table,
  Dropdown,
  Form,
  ButtonGroup,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import ApplicationSubmissionService from "../services/service/ApplicationSubmissionService";
import ErrorHandler from "../services/constant/ErrorHandler";
import UserDetails from "../services/storage/UserDetails";
import DashboardLink from "./DashboardLink";
import MUIDataTable from "mui-datatables";
import ApplicationService from "../services/service/ApplictionService";
import ApplicationUpdateService from "../services/service/ApplicationUpdateService";
import ReportOneg from "./Report/thirdchangeReport";
import BuildingPermissionOrder from "../screens/form/BuildingPermissionOrder";
import DemandNoticeSample from "../screens/form/DemandNoticeSample";
import { PDFExport } from "@progress/kendo-react-pdf";
import HighRise from "../services/constant/HighRise";
import Header from "./Header";
import moment from "moment/moment";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

const ActivityDashboardList = () => {
  const { saveHighRiseStatus } = HighRise();
  const [dashBoard, setDashBoard] = useState({});
  const { userDetails } = UserDetails();
  const location = useLocation();
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [dash, setDash] = useState(1);
  const history = useHistory();

  useEffect(() => {
    if (location?.state?.id) {
      let formdata = new FormData();
      formdata.append("user_application_id", location?.state?.id);
      ApplicationService.user_application_activities_dash({
        user_application_id: location?.state?.id,
      })
        .then((result) => {
          setDashBoard(result.data);
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      history.goBack();
    }
  }, []);

  console.log("list", list);

  return (
    <Col
      className="rt-dashboard"
      style={{
        background: "#edf0f2",
      }}
    >
      <Header />

      <Row className="px-2 bg-green p-2 border-bottom">
        <Col className="col-12 col-md-3 mb-2 mb-md-0">
          <p className="f14 mb-0">
            <i>File No: {location?.state?.file_no}</i>
          </p>
        </Col>
        <Col className="col-12 col-md-3 text-md-center mb-2 mb-md-0">
          <h4 className="f14 mb-0">
            <i>Proposal Status: Draft Mode</i>
          </h4>
        </Col>
        <Col className="col-12 col-md-6 text-md-end mb-2 mb-md-0">
          <p className="f14 mb-0">
            <i>
              {userDetails?.type == 1
                ? "Citizen"
                : userDetails?.type == 2
                ? "Architect"
                : "Structural Engineer"}{" "}
              : {userDetails?.title}. {userDetails?.first_name}{" "}
              {userDetails?.middle_name} {userDetails?.last_name}
            </i>
          </p>
        </Col>
      </Row>

      <Col className="d-flex justify-content-between w-100 px-2 bg-green border-bottom">
        <Link
          to={{
            pathname: "/application-view",
            state: location?.state,
          }}
          className="d-flex black align-items-center p-2"
        >
          <FontAwesomeIcon icon={faArrowCircleLeft} />
          <p className="mb-0 ms-2">Back</p>
        </Link>
        <Button className="proposal-risk-back">Proposal Risk Category</Button>
      </Col>

      <div>
        <>
          <div className="d-flex flex-wrap px-3 py-1">
            <div
              onClick={() => setDash(2)}
              className="dashBox d-flex grad-dash3"
            >
              <div
                activeClassName="ative-class"
                className="text-white w-100 d-grid p-2 m-auto text-center"
              >
                <h5 className="w-100 mb-0">Submitted Application</h5>
                <h2 className="w-100 mb-0">{dashBoard.submitted_count}</h2>
              </div>
            </div>
            <div
              onClick={() => setDash(3)}
              className="dashBox d-flex grad-dash4"
            >
              <div className="text-white w-100 d-grid p-2 m-auto text-center">
                <h5 className="w-100 mb-0">
                  Scrutiny Report
                  <br /> Generated
                </h5>
                <h2 className="w-100 mb-0">
                  {dashBoard.report_generated_count}
                </h2>
              </div>
            </div>
            <div
              onClick={() => setDash(4)}
              className="dashBox d-flex grad-dash5"
            >
              <div
                activeClassName="ative-class"
                className="text-white w-100 d-grid p-2 m-auto text-center"
              >
                <h5 className="w-100 mb-0">
                  Commissioner
                  <br /> Approved
                </h5>
                <h2 className="w-100 mb-0">
                  {dashBoard.commissioner_approved_count}
                </h2>
              </div>
            </div>
            <div
              onClick={() => setDash(5)}
              className="dashBox d-flex grad-dash6"
            >
              <div className="text-white w-100 d-grid p-2 m-auto text-center">
                <h5 className="w-100 mb-0">
                  Commissioner
                  <br /> Shortfall
                </h5>
                <h2 className="w-100 mb-0">
                  {dashBoard.commissioner_shortfall_count}
                </h2>
              </div>
            </div>
            <div
              onClick={() => setDash(6)}
              className="dashBox d-flex grad-dash7"
            >
              <div className="text-white w-100 d-grid p-2 m-auto text-center">
                <h5 className="w-100 mb-0">
                  Demand Note
                  <br /> Generated
                </h5>
                <h2 className="w-100 mb-0">
                  {dashBoard.demand_note_generated_count}
                </h2>
              </div>
            </div>
            <div
              onClick={() => setDash(7)}
              className="dashBox d-flex grad-dash8"
            >
              <div className="text-white w-100 d-grid p-2 m-auto text-center">
                <h5 className="w-100 mb-0">
                  Zonal Manager
                  <br /> Approved
                </h5>
                <h2 className="w-100 mb-0">{dashBoard.zm_approved_count}</h2>
              </div>
            </div>
            <div
              onClick={() => setDash(8)}
              className="dashBox d-flex grad-dash10"
            >
              <div className="text-white w-100 d-grid p-2 m-auto text-center">
                <h5 className="w-100 mb-0">
                  Zonal Manager
                  <br /> Shortfall
                </h5>
                <h2 className="w-100 mb-0">{dashBoard.zm_shortfall_count}</h2>
              </div>
            </div>
            <div
              onClick={() => setDash(9)}
              className="dashBox d-flex grad-dash9"
            >
              <div className="text-white w-100 d-grid m-auto text-center p-2">
                <h5 className="w-100 mb-0">
                  LAC(HO) <br /> (for High Rise)
                </h5>
                <h2 className="w-100 mb-0">{dashBoard.lacho_applications}</h2>
              </div>
            </div>
            <div
              onClick={() => setDash(10)}
              className="dashBox d-flex grad-dash2"
            >
              <div className="text-white w-100 d-grid p-2 m-auto text-center">
                <h5 className="w-100 mb-0">
                  DGM
                  <br /> (for High Rise)
                </h5>
                <h2 className="w-100 mb-0">{dashBoard.dgm_applications}</h2>
              </div>
            </div>
            <div
              onClick={() => setDash(19)}
              className="dashBox d-flex grad-dash8"
            >
              <div className="text-white w-100 d-grid p-2 m-auto text-center">
                <h5 className="w-100 mb-0">
                  CE <br /> (For High Rise)
                </h5>
                <h2 className="w-100 mb-0">{dashBoard.ce_applications}</h2>
              </div>
            </div>
            <div
              onClick={() => setDash(11)}
              className="dashBox d-flex grad-dash3"
            >
              <div className="text-white w-100 d-grid p-2  m-auto text-center">
                <h5 className="w-100 mb-0">
                  MSB/MOM Meeting
                  <br /> (for High Rise)
                </h5>
                <h2 className="w-100 mb-0">{dashBoard.lacho_mom_meeting}</h2>
              </div>
            </div>
            <div
              onClick={() => setDash(12)}
              className="dashBox d-flex grad-dash4"
            >
              <div className="text-white w-100 d-grid p-2 m-auto text-center">
                <h5 className="w-100 mb-0">
                  Demand Note
                  <br /> Issued
                </h5>
                <h2 className="w-100 mb-0">
                  {dashBoard.demand_note_issued_count}
                </h2>
              </div>
            </div>
            <div
              onClick={() => setDash(13)}
              className="dashBox d-flex grad-dash5"
            >
              <div className="text-white w-100 d-grid p-2 m-auto text-center">
                <h5 className="w-100 mb-0">
                  Final Payment
                  <br /> Completed
                </h5>
                <h2 className="w-100 mb-0">{dashBoard.final_payment_count}</h2>
              </div>
            </div>
            <div
              onClick={() => setDash(17)}
              className="dashBox d-flex grad-dash5"
            >
              <div className="text-white w-100 d-grid p-2 m-auto text-center">
                <h5 className="w-100 mb-0">
                  Final Payment
                  <br /> Rejected
                </h5>
                <h2 className="w-100 mb-0">
                  {dashBoard.upload_documents_resubmitted_count}
                </h2>
              </div>
            </div>
            <div
              onClick={() => setDash(14)}
              className="dashBox d-flex grad-dash6"
            >
              <div className="text-white w-100 d-grid m-auto text-center p-2">
                <h5 className="w-100 mb-0">
                  CE
                  <br />
                  Technical Approval
                  <br /> (for High Rise)
                </h5>
                <h2 className="w-100 mb-0">
                  {dashBoard.ce_technical_approved}
                </h2>
              </div>
            </div>
            <div
              onClick={() => setDash(15)}
              className="dashBox d-flex grad-dash7"
            >
              <div className="text-white w-100 d-grid p-2 m-auto text-center">
                <h5 className="w-100 mb-0">Sanctioned Files</h5>
                <h2 className="w-100 mb-0">{dashBoard.sanctioned_count}</h2>
              </div>
            </div>

            <div
              onClick={() => setDash(18)}
              className="dashBox d-flex grad-dash8"
            >
              <div className="text-white w-100 d-grid p-2 m-auto text-center">
                <h5 className="w-100 mb-0">Approved Sanction Files</h5>
                <h2 className="w-100 mb-0">
                  {dashBoard.digital_signature_count}
                </h2>
              </div>
            </div>
            <div
              onClick={() => setDash(20)}
              className="dashBox d-flex grad-dash8"
            >
              <div className="text-white w-100 d-grid p-2 m-auto text-center">
                <h5 className="w-100 mb-0">Staff Processing</h5>
                <h2 className="w-100 mb-0">{dashBoard.other_files}</h2>
              </div>
            </div>
            <div
              onClick={() => setDash(16)}
              className="dashBox d-flex grad-dash8"
            >
              <div className="text-white w-100 d-grid p-2 m-auto text-center">
                <h5 className="w-100 mb-0">Rejected Files</h5>
                <h2 className="w-100 mb-0">{dashBoard.rejected_count}</h2>
              </div>
            </div>
            {/* <div
                  onClick={() => setDash(21)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Occupancy Draft</h5>
                    <h2 className="w-100 mb-0">{"0"}</h2>
                  </div>
                </div>

                <div
                  onClick={() => setDash(22)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Occupancy Total</h5>
                    <h2 className="w-100 mb-0">{"0"}</h2>
                  </div>
                </div>

                <div
                  onClick={() => setDash(23)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Occupancy Submitted</h5>
                    <h2 className="w-100 mb-0">{"0"}</h2>
                  </div>
                </div>

                <div
                  onClick={() => setDash(24)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Occupancy Status</h5>
                    <h2 className="w-100 mb-0">{"0"}</h2>
                  </div>
                </div>

                <div
                  onClick={() => setDash(25)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Occupancy Shortfall</h5>
                    <h2 className="w-100 mb-0">{"0"}</h2>
                  </div>
                </div> */}
          </div>
        </>
      </div>
    </Col>
  );
};

export default ActivityDashboardList;
