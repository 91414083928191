import React, { useState } from "react";
import {
  Form,
  InputGroup,
  FormControl,
  Container,
  Row,
  Col,
  Modal,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Logo,
  DPMS,
  LTP,
  CIT,
  Arrow,
  OnlinePay,
  AppliBtn,
  Newgif,
  FooterBack,
  FooterLogo,
} from "../assets/images/Index";
import Login from "../component/Login";
import Pdf from "../assets/images/CommonAffidavitUndertaking.pdf";

const Signup = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Col>
        <Row className="justify-content-center">
          <Col xs={12} md={10}>
            <div className="pt-1 justify-content-center">
              <div className="align-items-center justify-content-between one-edge-shadow">
                <Row>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-9 mb-2 mb-sm-0">
                    <div className="d-flex align-items-center">
                      &nbsp;
                      <Logo />
                      <div className="mx-2 ps-2 d-block d-sm-none">
                        <h5 className="f-x-large fw-bold mx-0 mt-1 ms-4">
                          TGIIC
                        </h5>
                      </div>
                      <div className="mx-2 ps-2 d-none d-sm-block">
                        <h4 className="f-x-large m-0">
                          <strong>
                            Telangana Industrial Infrastructure Corporation Ltd.
                          </strong>
                        </h4>
                        <h5 className="f13 mx-0 mt-1 ms-0">
                          (A GOVT. OF TELANGANA UNDERTAKING)
                        </h5>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-3 text-center text-md-end d-none d-lg-block">
                    <DPMS />
                  </Col>
                </Row>
              </div>
              <Login setToken={props.setToken} />
              <marquee width="100%" direction="left" className="marq-box">
                <p className="text-danger mb-0 marq-p">
                  {/* Architects/Consultants can log in online applications for
                  building permission for Commercial and Residential types of
                  properties. Kindly refer to updates User Manual and AutoCAD
                  Template for guidance */}
                  {/* 19-10-2024 */}
                  {/* Architects/ Consultants : Pl use updated user manual ver 3.2
                  and Autocad Drawing Template ported now while color
                  coding/drafting your drawings */}
                  Architects/ Consultants: Please use the updated User Manual
                  Ver 3.4 and AutoCAD Drawing Template while color
                  coding/drafting your drawings. Please refer to the User Manual
                  for Submission for Occupancy Certificate Files.
                </p>
              </marquee>

              <Row className="justify-content-md-center">
                <Col xs={12} md={12} className="my-3 mx-lg-0">
                  <h4 className="f16 c7 mb-0 mt-2">
                    <strong>
                      TELANGANA - The Preferred Investment destination.
                    </strong>
                  </h4>
                  <p className="mb-0 mt5 f13 w-100">
                    TGIIC (Telangana State Industrial Infrastructure
                    Corporation) also known as TGIIC is a Telangana State
                    Government initiative for providing infrastructure through
                    development of industrial areas.
                  </p>
                </Col>
              </Row>

              <Row className="mt-3 justify-content-md-center my-2">
                <div className="col-lg-4 col-xl-3 pe-3">
                  <div className="d-lg-flex justify-content-between ">
                    <div>
                      <p>
                        <Link to="/dashboard" className="c6">
                          Dashboard
                        </Link>
                      </p>
                    </div>
                    <div className="">
                      <p>
                        <a className="c6">Downloads</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-xl-9">
                  <a href={Pdf} className="f11 black" target="_blank">
                    Common Affidavit by Owner, Builders and LTPs. &nbsp;
                    <Newgif />
                  </a>
                </div>
              </Row>

              <div className="footer-banner-img">
                <div className="content-box pt-4">
                  <Row className="py-2 mt-3">
                    <div className="col-12 col-md-12 col-lg-6 d-flex align-items-center ms-25 m-md-0">
                      <FooterLogo />
                      <div className="ms-4 d-block">
                        <h4 className="f12 black m-0 ">
                          GOVERNMENT OF TELANGANA
                        </h4>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <h4 className="f12 black mt-2 ps-0 ps-lg-5">
                        WELCOME TO TGIIC DEVELOPMENT PERMISSION MANAGEMENT
                        SYSTEM DEVELOPED ON Developed on Autometry-ABPAS
                        platform.
                        {/* AutoDCR® PLATFORM. */}
                      </h4>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="justify-content-center">
              <div className="f14 text-center">
                Proceed for Inspection Checklist
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label className="mt-2 mb-0 f14">Mobile No</Form.Label>
            <InputGroup className="mb-1">
              <FormControl className="inputBox" />
            </InputGroup>
            <Form.Label className="mt-2 mb-0 f14">Unique Code</Form.Label>
            <InputGroup className="mb-1">
              <FormControl className="inputBox" />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button variant="outline-secondary" onClick={handleClose}>
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>
      </Col>
    </>
  );
};

export default Signup;
